import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useDebounce } from '../../../../libraries/useDebounce';
import { symbolLookUp } from '../../../../api/fasttrack-service';
import Select from 'react-select';
import { convertToNumber } from "../../../../libraries/utils";

interface AddSecurityModalProps {
  isOpen: boolean,
  onClose: () => void,
  onSave: (e: { symbol: string, name: string, marketValue: number, securityId?: string }) => void,
  isSecurityAdding: boolean,
  selectedSecurity?: any,
}

const AddSecurityModal: React.FC<AddSecurityModalProps> = ({
                                                             isOpen,
                                                             onClose,
                                                             onSave,
                                                             isSecurityAdding,
                                                             selectedSecurity
                                                           }) => {

  const textColorSecondary = 'gray.500';
  const brandColor = useColorModeValue('brand.500', 'white');
  const [securityName, setSecurityName] = useState<string>('');
  const [errorFlag, setErrorFlag] = useState<boolean>(false);
  const [isSecurityLoading, setIsSecurityLoading] = useState<boolean>(false);
  const [securityList, setSecurityList] = useState([]);
  const [security, setSecurity] = useState<any>(null);
  const debouncedFilter = useDebounce(securityName, 700);
  const [marketValue, setMarketValue] = useState('');

  useEffect(() => {
    if (securityName.length > 1) {
      setIsSecurityLoading(true);
      symbolLookUp(securityName)
        .then(({symbols}) => {
          const list = symbols.map((item: any) => ({
            value: item.symbol,
            label: `${item.symbol} -- ${item.name}`
          }))
          setSecurityList(list);
        })
        .catch((err) => {
          console.error(err.message);
          setSecurityList([]);
        })
        .finally(() => setIsSecurityLoading(false))
    }
  }, [debouncedFilter])

  useEffect(() => {
    if (selectedSecurity) {
      setSecurityName(selectedSecurity?.tickerSymbol);
      setMarketValue(selectedSecurity?.marketValue);
      if (selectedSecurity?.tickerSymbol !== selectedSecurity?.name) {
        setSecurity({
          value: selectedSecurity?.tickerSymbol,
          label: `${selectedSecurity.tickerSymbol} -- ${selectedSecurity.name}`
        });
      }
    } else {
      setSecurityName('');
      setMarketValue('');
      setSecurityList([]);
      setSecurity(null);
    }
  }, [selectedSecurity]);


  const save = () => {
    if (!security) {
      setErrorFlag(true);
    } else {
      onSave({
        symbol: security?.value,
        name: security?.label,
        marketValue: convertToNumber(marketValue),
        ...(selectedSecurity ? {securityId: selectedSecurity?.securityId} : {}),
      });
    }
  }

  const handleClose = () => {
    onClose();
    setSecurityName('');
    setSecurity(null);
    setMarketValue('');
    setErrorFlag(false);
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>{selectedSecurity ? 'Edit' : 'Add'} Ticker</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <Text mb="12px" color={textColorSecondary} fontSize="sm">Please ensure the ticker symbol and
            security name are
            accurate. We will obtain the fees and market data via holdings.</Text>

          {
            selectedSecurity &&
            <Box mb="12px">
              <Text>Ticker Name (Suggested)</Text>
              <Text fontWeight='500' fontSize='lg' color='brand.500'>{selectedSecurity?.name}</Text>
            </Box>
          }

          <FormControl id="SecurityName" isRequired>
            {errorFlag ? (
              <FormHelperText color="red">
                {!security ? 'Security is required' : 'Security not found'}
              </FormHelperText>
            ) : null}

            <FormLabel>Ticker Symbol</FormLabel>
            <Select
              isLoading={isSecurityLoading}
              value={security}
              isClearable
              placeholder="Input Security Name or Ticker"
              onInputChange={(value: string) => setSecurityName(value)}
              onChange={(value: string) => setSecurity(value)}
              isSearchable
              options={securityList}
            />
          </FormControl>
          {
            security && <Box mt="20px">
              <Text color={brandColor} fontSize="lg" fontWeight="500">Found Ticker</Text>
              <Flex>
                <Text fontSize="md" fontWeight="700" mr="4px">Ticker Symbol: </Text>
                <Text fontSize="md">{security?.value}</Text>
              </Flex>
              <Flex>
                <Text fontSize="md" fontWeight="700" mr="4px">Name: </Text>
                <Text fontSize="md">{security?.label?.split('--')[1].trim()}</Text>
              </Flex>
            </Box>
          }

          <FormControl mt={3}>
            <FormLabel>Market Value</FormLabel>
            <Input placeholder="0" value={marketValue} type='number'
                   onChange={(e) => setMarketValue(e.target.value)}/>
          </FormControl>

        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} isLoading={isSecurityAdding}
                  onClick={() => save()}>{selectedSecurity ? 'Update' : 'Add'}</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddSecurityModal;
