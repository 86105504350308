import apiRequest from './api';
import { BASE_API_URL } from '../config/constant';


export const generateConnectUrl = () => {
	return apiRequest('post', `${BASE_API_URL}/finicity/generate_connect_url`).then(res => res.data);
};

export const generateUploadtUrl = () => {
	return apiRequest('post', `${BASE_API_URL}/finicity/generate_upload_url`).then(res => res.data);
};

export const generateConnectFixUrl = (institutionLoginId: string) => {
	return apiRequest('post', `${BASE_API_URL}/finicity/generate_connect_fix_url/${institutionLoginId}`).then(res => res.data);
};

export const downloadBankStatement = (email: string, accountId: string, type: string, index: number) => {
	return apiRequest('post', `${BASE_API_URL}/finicity/accounts/${accountId}/statement`, {
		body: {
			email,
			index,
			type
		},
	}).then(res => res.data);
};