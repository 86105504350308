import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { currencyFormat } from "../../../../libraries/utils";
import { useAuth } from "../../../../auth-context/auth.context";
import moment from "moment";
import { GaugeComponent } from 'react-gauge-component';
import { getTickerByCusipNumber } from "../../../../api/fasttrack-service";
import ReactApexChart from "react-apexcharts";

interface SmarttaxReportCardModalProps {
  isOpen: boolean,
  onClose: () => void,
  title: string,
  type: 'TRT' | 'W&I' | 'ACCT',
  transcripts: any,
}

type FilingStatus = 'Single' | 'Married Filing Joint' | 'Head Of Household' | 'N/A';

const SmarttaxReportCardModal: React.FC<SmarttaxReportCardModalProps> = ({
                                                                           isOpen,
                                                                           onClose,
                                                                           title,
                                                                           type,
                                                                           transcripts
                                                                         }) => {

  const transcript = transcripts[type];

  // Chakra Color Mode
  const textColor = useColorModeValue("navy.700", "white");

  const { user, selectedClient, } = useAuth();

  const isZeroValue = (value: string): boolean => {
    return value === '$0.00' || value === '$0' || value === '0';
  };

  const areAllItemsZero = (items: any[]) => {
    return items.every(item => isZeroValue(item.value));
  };

  const calculateEffectiveTaxRate = (transcripts: any) => {
    let totalTaxLiability = 0;
    let totalIncome = 0;

    transcripts.forEach((transcript: any) => {
      transcript.sections.forEach((section: any) => {
        section.items.forEach((item: any) => {
          if (item.description === "TOTAL TAX LIABILITY TP FIGURES PER COMPUTER") {
            totalTaxLiability += parseFloat(item.value.replace('$', '').replace(',', ''));
          }
          if (item.description === "ADJUSTED GROSS INCOME PER COMPUTER") {
            totalIncome += parseFloat(item.value.replace('$', '').replace(',', ''));
          }
        });
      });
    });

    return totalIncome ? (totalTaxLiability / totalIncome * 100).toFixed(2) : 'N/A';
  };

  const calculateNetCapitalGain = (transcripts: any) => {
    let netCapitalGain = 0;

    transcripts.forEach((transcript: any) => {
      transcript.sections.forEach((section: any) => {
        section.items.forEach((item: any) => {
          if (item.description === "F8995 NET CAPITAL GAINS") {
            netCapitalGain += parseFloat(item.value.replace('$', '').replace(',', ''));
          }
        });
      });
    });

    return netCapitalGain === 0 ? 'N/A' : `$${currencyFormat(netCapitalGain)}`;
  };

  const calculateNumberOfDependents = (transcript: any) => {
    let dependentsCount = 0;
    let index = 1;
    while (transcript[`dependent${index}NameCtrl`] && transcript[`dependent${index}Ssn`]) {
      dependentsCount++;
      index++;
    }
    return dependentsCount || 'N/A';
  };

  const extractDetails = (transcript: any, category: string) => {
    return transcript.sections.find((section: any) =>
      section.description.startsWith(category)
    );
  };

  const calculateTotal = (subsections: any, descriptions: string[]) => {
    return subsections?.reduce((acc: number, subsection: any) => {
      subsection?.items?.forEach((item: any) => {
        if (descriptions.includes(item.description)) {
          acc += parseFloat(item.value.replace('$', '').replace(',', '')) || 0;
        }
      });
      return acc;
    }, 0);
  };

  const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
  const [tickerMap, setTickerMap] = useState<Record<string, string>>({});
  const [fetchingCusips, setFetchingCusips] = useState<Set<string>>(new Set());

  const fetchTickerForCusip = async (cusip: string) => {
    if (cusip && !tickerMap[cusip] && !fetchingCusips.has(cusip)) {
      setFetchingCusips((prev) => new Set(prev).add(cusip)); // Mark CUSIP as being fetched
      try {
        const { ticker } = await getTickerByCusipNumber(cusip);
        setTickerMap((prevMap) => ({ ...prevMap, [cusip]: ticker || 'N/A' })); // Save ticker into cache
      } catch (error) {
        console.error(`Failed to fetch ticker for CUSIP ${cusip}:`, error);
        setTickerMap((prevMap) => ({ ...prevMap, [cusip]: 'N/A' })); // Use 'N/A' as fallback
      } finally {
        setFetchingCusips((prev) => {
          const updated = new Set(prev);
          updated.delete(cusip);
          return updated; // Remove CUSIP from fetching set
        });
      }
    }
  };

  const renderWithTicker = (cusipNumber: string) => {
    if (!cusipNumber) return 'N/A';

    // Trigger fetch if not in cache
    if (!tickerMap[cusipNumber]) {
      fetchTickerForCusip(cusipNumber);
    }

    // Return the ticker or placeholder while it's loading
    return tickerMap[cusipNumber] ? `(${tickerMap[cusipNumber]})` : '(Loading...)';
  };

  const handleExpandCategory = (title: string, section: any) => {
    setSelectedTitle(title);
    setSelectedSection(section);
    onDetailOpen();
  };

  // Extract additional information from transcript
  const nameOnReturn = transcripts["TRT"]?.nameShownOnReturn || 'N/A';
  const filingStatus = transcripts["TRT"]?.filingStatus || 'N/A';
  const numberOfDependents = calculateNumberOfDependents(transcripts["TRT"]);

  const effectiveTaxRate = calculateEffectiveTaxRate([transcripts["TRT"]]);
  const netCapitalGain = calculateNetCapitalGain([transcripts["TRT"]]);
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [selectedTitle, setSelectedTitle] = useState('');

  const incomeSection = extractDetails(transcripts["TRT"], 'Income');
  const scheduleCSection = extractDetails(transcript, 'Schedule C');
  const scheduleESection = extractDetails(transcript, 'Schedule E');
  const scheduleK1Section = extractDetails(transcript, 'Schedule K-1');
  const scheduleDSection = extractDetails(transcript, 'Schedule D');
  const form8889Section = extractDetails(transcript, 'Form 8889');
  const scheduleASection = extractDetails(transcript, 'Schedule A');
  const refundSection = extractDetails(transcript, 'Refund or Amount Owed');
  const adjustmentsToIncomeSection = extractDetails(transcript, 'Adjustments to Income');
  const form8995Section = extractDetails(transcript, 'Form 8995');
  const form8863EducationSection = extractDetails(transcript, 'Form 8863');
  const creditsSection = extractDetails(transcript, 'Tax and Credits');
  const otherTaxesSection = extractDetails(transcript, 'Other Taxes');
  const scheduleSESection = extractDetails(transcript, 'Schedule SE');
  const w2WageAndTaxStatement = extractDetails(transcript, 'Form W-2 Wage and Tax Statement');
  const form1099Sections = transcript.sections.filter((section: any) =>
    section.description.includes("1099")
  );
  const form5498Section = extractDetails(transcript, 'Form 5498');

  const ordinaryIncomeK1 = calculateTotal([scheduleK1Section], ['Ordinary Income K-1']);
  const totalIncome = incomeSection?.items?.find((item: any) => item.description === "TOTAL INCOME PER COMPUTER")?.value?.replace(/[$,]/g, '') || 0;
  const totalQualifiedBusinessLossCarryForward = form8995Section?.items?.find((item: any) => item.description === "TOTAL QUALIFIED BUSINESS LOSS CARRYFORWARD")?.value || 'N/A';
  const totalQualifiedReitDiv = form8995Section?.items?.find((item: any) => item.description === "QUALIFIED REIT DIV AND PTP LOSS CARRYFORWARD")?.value || 'N/A';
  const qualifiedBusinessNetLossCarryForward = form8995Section?.items?.find((item: any) => item.description === "QUALIFED BUSINESS NET LOSS CARRYFORWARD")?.value || 'N/A';
  const retirementIndicator = w2WageAndTaxStatement?.items?.find((item: any) => item.description === "Retirement Plan Indicator")?.value || 'N/A';

  const calculateMortgageInterestRate = (interest: string, principal: string) => {
    // Remove dollar signs and commas
    const cleanedInterest = interest.replace(/[$,]/g, '');
    const cleanedPrincipal = principal.replace(/[$,]/g, '');

    // Parse the cleaned string
    const interestValue = parseFloat(cleanedInterest);
    const principalValue = parseFloat(cleanedPrincipal);

    // Calculate interest rate
    return principalValue !== 0 ? ((interestValue / principalValue) * 100).toFixed(2) + '%' : 'N/A';
  };

  const mortgageSections = transcript.sections.filter((section: any) =>
    section.description === "Form 1098 Mortgage Interest Statement" &&
    section.items.find((item: any) => item.description === "Mortgage Origination Date")
  );

  function getTaxBracket(income: number, filingStatus: FilingStatus): any {
    if (filingStatus === 'N/A') {
      return 'N/A';
    }

    const brackets = {
      Single: [
        { upper: 11600, rate: 0.10 }, // Changed rate to decimal format
        { upper: 47150, rate: 0.12 },
        { upper: 100525, rate: 0.22 },
        { upper: 191950, rate: 0.24 },
        { upper: 243725, rate: 0.32 },
        { upper: 609350, rate: 0.35 },
        { rate: 0.37 }, // Last bracket doesn't have an upper value (infinity)
      ],
      'Married Filing Joint': [
        { upper: 23200, rate: 0.10 },
        { upper: 94300, rate: 0.12 },
        { upper: 201050, rate: 0.22 },
        { upper: 383900, rate: 0.24 },
        { upper: 487450, rate: 0.32 },
        { upper: 731200, rate: 0.35 },
        { rate: 0.37 },
      ],
      'Head Of Household': [
        { upper: 16550, rate: 0.10 },
        { upper: 63100, rate: 0.12 },
        { upper: 100500, rate: 0.22 },
        { upper: 191950, rate: 0.24 },
        { upper: 243700, rate: 0.32 },
        { upper: 609350, rate: 0.35 },
        { rate: 0.37 },
      ],
    };

    // Check if the bracket is defined
    const bracket: any = brackets[filingStatus];
    if (!bracket) {
      return 'N/A';
    }

    for (let i = 0; i < bracket.length; i++) {
      if (!bracket[i].upper || income <= bracket[i].upper) {
        return bracket[i].rate;
      }
    }

    return 'N/A';
  }

  const render1099Items = (section: any, descriptions: string[]) => {
    return descriptions.map((description) => {
      const foundItem = section.items.find((item: any) => item.description === description);

      return foundItem ? (
        <React.Fragment key={description}>
          <HStack justify='space-between' w='full'>
            <Text textAlign='start' fontWeight='bold'>
              {foundItem.description}:
            </Text>
            <Text>
              {foundItem.value || 'N/A'}
            </Text>
          </HStack>
          <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
        </React.Fragment>
      ) : null;
    });
  };

  const getEffectiveTaxRateText = (value: any): string => {
    if (value <= 16) {
      return `Excellent - ${value}%`;
    } else if (value > 16 && value <= 20) {
      return `Average - ${value}%`;
    } else if (value > 20 && value <= 25) {
      return `Below Average - ${value}%`;
    } else {
      return `Unsatisfactory - ${value}%`;
    }
  };

  const parseTaxRate = (rate: string): number => {
    const parsedValue = parseFloat(rate);
    return isNaN(parsedValue) ? 0 : parsedValue; // Default to 0 if invalid
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader mt={6}>
            <HStack color={textColor} justify='space-between'>
              <Text fontSize='2xl'>{selectedClient?.firstName} {selectedClient?.lastName} smarttax™ Report Card</Text>
              <Image cursor="pointer" src={user?.firm?.logoUrl} h='32px' display={{ base: 'none', md: 'block' }}/>
            </HStack>
          </ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1} alignItems="start">
              <Text><strong>Name on Return:</strong> {nameOnReturn}</Text>
              <Text><strong>Filing Status:</strong> {filingStatus}</Text>
              <Text><strong>Number of Dependents:</strong> {numberOfDependents}</Text>
              <Text><strong>Net Capital Gain:</strong> {netCapitalGain}</Text>
              <Box w={{ base: 'full', md: '80%', xl: '60%' }}>
                <Text><strong>Effective Tax Rate:</strong> {effectiveTaxRate}%</Text>
                {
                  effectiveTaxRate !== 'N/A' &&
                  <GaugeComponent
                    type="semicircle"
                    style={{
                      margin: 'auto',
                    }}
                    arc={{
                      width: 0.2,
                      padding: 0.005,
                      cornerRadius: 1,
                      // gradient: true,
                      subArcs: [
                        {
                          limit: 16,
                          color: '#4CAF50',
                          showTick: true,
                          tooltip: {
                            text: 'Excellent',
                          },
                        },
                        {
                          limit: 20,
                          color: '#2196F3',
                          showTick: true,
                          tooltip: {
                            text: 'Average'
                          }
                        },
                        {
                          limit: 25,
                          color: '#FFEB3B',
                          showTick: true,
                          tooltip: {
                            text: 'Below Average'
                          }
                        },
                        {
                          color: '#F44336',
                          tooltip: {
                            text: 'Unsatisfactory'
                          }
                        },
                      ]
                    }}
                    pointer={{
                      type: 'arrow',
                      length: 0.80,
                      width: 10,
                      elastic: true,
                    }}
                    labels={{
                      valueLabel: {
                        formatTextValue: getEffectiveTaxRateText,
                        matchColorWithArc: true,
                        style: { fontSize: 60, }
                      },
                      tickLabels: {
                        type: 'outer',
                        defaultTickValueConfig: {
                          formatTextValue: (value: any) => value + '%',
                          style: { fontSize: 16 }
                        },
                      }
                    }}
                    value={parseTaxRate(effectiveTaxRate)}
                    minValue={0}
                    maxValue={37}
                  />
                }
              </Box>
              <Box>
                <Text fontWeight="bold">
                  Tax Bracket Range:
                </Text>
                <TaxBracketRange income={totalIncome} filingStatus={filingStatus}/>
              </Box>
            </SimpleGrid>

            <Text mt={1}>
              <strong>TOTAL QUALIFIED BUSINESS LOSS CARRYFORWARD: </strong> {totalQualifiedBusinessLossCarryForward}
            </Text>
            <Text mt={1}><strong>QUALIFIED REIT DIV AND PTP LOSS CARRYFORWARD:</strong> {totalQualifiedReitDiv}</Text>
            <Text mt={1}><strong>QUALIFIED BUSINESS NET LOSS
              CARRYFORWARD:</strong> {qualifiedBusinessNetLossCarryForward}</Text>

            {incomeSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Income
                </Text>

                {['TOTAL INCOME PER COMPUTER', 'TOTAL SOCIAL SECURITY BENEFITS', 'TOTAL IRA DISTRIBUTIONS'].map((description) => {
                  const totalValue = calculateTotal([incomeSection], [description]);
                  const foundItem = incomeSection.items.find((item: any) => item.description === description);

                  return foundItem ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory('Income', incomeSection);
                        }}
                      >
                        <Text
                          textAlign='start'
                          fontWeight='bold'
                        >
                          {foundItem.description}:
                        </Text>
                        <Text>
                          ${currencyFormat(totalValue)}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
                <HStack
                  justify='space-between'
                  w='full'
                >
                  <Text
                    textAlign='start'
                    fontWeight='bold'
                  >
                    Income tax bracket and rate:
                  </Text>
                  <Text>
                    {getTaxBracket(totalIncome, filingStatus) !== 'N/A' ? `${getTaxBracket(totalIncome, filingStatus) * 100}%` : 'N/A'}
                  </Text>
                </HStack>
                <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
              </VStack>
            )}

            {w2WageAndTaxStatement && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Form W-2 Wage and Tax Statement
                </Text>
                <HStack
                  as='button'
                  textColor='blue.500'
                  justify='space-between'
                  w='full'
                  onClick={() => {
                    handleExpandCategory('Form W-2 Wage and Tax Statement', w2WageAndTaxStatement);
                  }}
                >
                  <Text
                    textAlign='start'
                    fontWeight='bold'
                  >
                    Retirement Indicator:
                  </Text>
                  <Text color={retirementIndicator.startsWith('Yes') ? 'green' : 'red'}>
                    {retirementIndicator.startsWith('Yes') ? 'Yes' : 'No'}
                  </Text>
                </HStack>
                <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
              </VStack>
            )}

            {scheduleCSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Schedule C
                </Text>
                <HStack
                  as='button'
                  textColor='blue.500'
                  justify='space-between'
                  w='full'
                  onClick={() => {
                    handleExpandCategory('Schedule C', scheduleCSection);
                  }}
                >
                  <Text
                    textAlign='start'
                    fontWeight='bold'
                  >
                    Business Name:
                  </Text>
                  <Text>
                    {scheduleCSection.items.find((item: any) => item.description === "BUSINESS NAME")?.value || 'N/A'}
                  </Text>
                </HStack>
                <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
              </VStack>
            )}

            {scheduleESection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Schedule E-Supplemental Income and Loss
                </Text>
                {scheduleESection.subsections.map((subsection: any, index: number) => {
                  const subsectionTotalIncome = calculateTotal([subsection], subsection.items.map((item: any) => item.description));
                  if (subsectionTotalIncome > 0) {
                    return (
                      <Box w='full' key={index}>
                        <HStack
                          as='button'
                          textColor='blue.500'
                          justify='space-between'
                          w='full'
                          onClick={() => {
                            handleExpandCategory(`Schedule E-Supplemental Income and Loss - ${subsection.description}`, subsection);
                          }}
                        >
                          <Text
                            textAlign='start'
                            fontWeight='bold'
                          >
                            {subsection.description}:
                          </Text>
                          <Text>
                            ${currencyFormat(subsectionTotalIncome)}
                          </Text>
                        </HStack>
                        <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                      </Box>

                    );
                  }
                  return null;
                })}
              </VStack>
            )}

            {scheduleK1Section && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Schedule K-1
                </Text>
                <VStack
                  align='start'
                  w='full'
                >
                  <Text
                    textAlign='start'
                    fontWeight='bold'
                  >
                    {scheduleK1Section?.businessLabel}:
                  </Text>
                  <Text>{scheduleK1Section?.businessLabel}'s Employer Identification
                    Number: {scheduleK1Section?.businessEin}</Text>
                  <Text>{scheduleK1Section?.businessAddress1} {scheduleK1Section?.businessAddress2} {scheduleK1Section?.businessCity}, {scheduleK1Section?.businessState} {scheduleK1Section?.businessZip}</Text>
                </VStack>
                <HStack
                  as='button'
                  textColor='blue.500'
                  justify='space-between'
                  w='full'
                  onClick={() => {
                    handleExpandCategory('Schedule K-1', scheduleK1Section);
                  }}
                >
                  <Text
                    textAlign='start'
                    fontWeight='bold'
                  >
                    Ordinary Income K-1 total:
                  </Text>
                  <Text color={ordinaryIncomeK1 > 0 ? 'green' : ordinaryIncomeK1 < 0 ? 'red' : 'black'}>
                    ${currencyFormat(ordinaryIncomeK1)}
                  </Text>
                </HStack>
              </VStack>
            )}

            {scheduleDSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Schedule D: Capital Gains and Losses
                </Text>

                {['TAX COMPUTATION USING MAXIMUM CAPITAL GAINS RATES', 'LONG TERM CAPITAL GAINS AND LOSSES', 'SHORT TERM CAPITAL GAINS AND LOSSES'].map((description) => {
                  const subsection = scheduleDSection.subsections.find((sub: any) => sub.description === description);
                  let label;

                  switch (description) {
                    case 'TAX COMPUTATION USING MAXIMUM CAPITAL GAINS RATES':
                      label = 'CAP GAINS PER COMPUTER';
                      break;
                    case 'LONG TERM CAPITAL GAINS AND LOSSES':
                      label = 'NET LONG-TERM GAIN/LOSS';
                      break;
                    case 'SHORT TERM CAPITAL GAINS AND LOSSES':
                      label = 'NET SHORT-TERM GAIN/LOSS';
                      break;
                    default:
                      label = '';
                  }

                  const totalValue = subsection ? calculateTotal([subsection], [label]) : null;

                  return subsection && totalValue > 0 ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory(`Schedule D: Capital Gains and Losses - ${subsection.description}`, subsection);
                        }}
                      >
                        <Text textAlign='start' fontWeight='bold'>
                          {label}:
                        </Text>
                        <Text>
                          ${currencyFormat(totalValue)}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

            {/* Form 1099 Section */}
            {form1099Sections.length > 0 && (
              <VStack align="start" spacing={1} mt={4}>
                {form1099Sections.map((section: any, index: number) => {
                  return (
                    <VStack align="start" spacing={1} mt={4} key={index} w='full'>
                      <Text
                        as='button'
                        textColor='blue.500'
                        fontSize='lg'
                        fontWeight='bold'
                        onClick={() => {
                          handleExpandCategory(section.description, section);
                        }}
                      >
                        {section.description}
                      </Text>

                      <Text fontWeight='bold'>{section.businessLabel}: </Text>
                      <Text>Payer Identification Number: {section.businessEin || 'N/A'}</Text>
                      <Text>Address: {section.businessAddress1} {section.businessAddress2}, {section.businessCity}, {section.businessState} {section.businessZip}</Text>

                      <Text fontWeight='bold'>{section.individualLabel}: </Text>
                      <Text>Recipient Identification Number: {section.individualSsn || 'N/A'}</Text>
                      <Text>Address: {section.individualAddress1} {section.individualAddress2}, {section.individualCity}, {section.individualState} {section.individualZip}</Text>

                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>

                      {section.description?.includes("Form 1099-B") && (() => {
                        const proceedsItem = section.items.find((item: any) => item.description === "Proceeds")?.value || "$0.00";
                        const costOrBasisItem = section.items.find((item: any) => item.description === "Cost or Basis")?.value || "$0.00";
                        const washSaleLossDisallowedItem = section.items.find((item: any) => item.description === "Wash Sale Loss Disallowed")?.value || "$0.00";
                        const cusipNumber = section.items.find((item: any) => item.description === "CUSIP Number")?.value || "";

                        const getValue = (value: string) => parseFloat(value.replace(/[$,]/g, "")) || 0;

                        const proceeds = getValue(proceedsItem);
                        const costOrBasis = getValue(costOrBasisItem);
                        const washSaleLossDisallowed = getValue(washSaleLossDisallowedItem);

                        // Calculate Total Loss or Gain
                        const totalGainOrLoss = proceeds - costOrBasis + washSaleLossDisallowed;
                        const isGain = totalGainOrLoss > 0;

                        // Determine the color based on Gain or Loss
                        const gainOrLossColor = isGain ? "green.500" : totalGainOrLoss < 0 ? "red.500" : "gray.500";

                        // Determine applicable SmartTax Fee
                        let smartTaxFee = 0;

                        const isLongTerm = section.items.find((item: any) => item.description === "Type of Gain or Loss Code")?.value === "Long-term";

                        if (isGain) {
                          let taxRate = 0;

                          if (isLongTerm) {
                            // Long-term gain tax rates based on filing status and taxable income
                            const longTermRates: any = {
                              "Single": [
                                { max: 47025, rate: 0.0 },
                                { max: 518900, rate: 0.15 },
                                { max: Infinity, rate: 0.20 }
                              ],
                              "Married Filing Joint": [
                                { max: 94050, rate: 0.0 },
                                { max: 583750, rate: 0.15 },
                                { max: Infinity, rate: 0.20 }
                              ],
                              "Married Filing Separate": [
                                { max: 47025, rate: 0.0 },
                                { max: 291850, rate: 0.15 },
                                { max: Infinity, rate: 0.20 }
                              ],
                              "Head of Household": [
                                { max: 63000, rate: 0.0 },
                                { max: 551350, rate: 0.15 },
                                { max: Infinity, rate: 0.20 }
                              ],
                            };

                            // Find applicable rate for the user's filing status and taxable income
                            taxRate = longTermRates[filingStatus]?.find((rate: any) => totalIncome <= rate.max)?.rate || 0;
                          } else {
                            // Short-term gain: use ordinary income tax bracket rate (hardcoded for example)
                            const taxbracket = getTaxBracket(totalIncome, filingStatus);
                            if (taxbracket !== 'N/A') {
                              taxRate = taxbracket;
                            }
                          }
                          smartTaxFee = totalGainOrLoss * taxRate;
                        }

                        return (
                          <VStack align="start" spacing={1} mt={4} w="full">
                            {/* Long Term Calculation: Total Gain times the percent tax bracket to show the SmartTax Fee */}
                            <HStack justify="space-between" w="full">
                              <Text fontWeight='bold'>Total Gain/Loss:</Text>
                              <Text color={gainOrLossColor} fontWeight="bold">
                                ${currencyFormat(totalGainOrLoss)}
                              </Text>
                            </HStack>
                            <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>

                            {isGain && (
                              <HStack justify="space-between" w="full">
                                <Text fontWeight='bold'>SmartTax Fee
                                  (at {isLongTerm ? "Long-term" : "Short-term"} Rate):</Text>
                                <Text color="blue.500" fontWeight="bold">
                                  ${currencyFormat(smartTaxFee)}
                                </Text>
                              </HStack>
                            )}

                            {render1099Items(section, [
                              "Type of Gain or Loss Code",
                              "Description",
                            ])}

                            <HStack justify="space-between" w="full">
                              <Text fontWeight='bold'>
                                CUSIP Number:</Text>
                              <Text fontWeight="bold">
                                {cusipNumber} {renderWithTicker(cusipNumber)}
                              </Text>
                            </HStack>
                          </VStack>
                        );
                      })()}

                      {section.description?.includes("Form SSA-1099") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Pensions and Annuities (Total Benefits Paid)'])}
                        </VStack>
                      )}

                      {section.description?.includes("Form 5498") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Fair Market Value of Account'])}
                        </VStack>
                      )}

                      {section.description?.includes("Form 1099-G") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Prior Year Refund'])}
                        </VStack>
                      )}

                      {section.description?.includes("Form 1099-MISC") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Fish Purchased for Resale', 'Excess Golden Parachute', 'Other Income', 'Rents', 'Royalties', 'Fishing Income'])}
                        </VStack>
                      )}

                      {section.description?.includes("Form 1099-DIV") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Ordinary Dividend'])}
                        </VStack>
                      )}

                      {section.description?.includes("Form 1099-INT") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Interest'])}
                        </VStack>
                      )}

                      {section.description?.includes("Form 1099-R") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Gross Distribution', 'Total Distribution', 'Taxable Amount'])}
                        </VStack>
                      )}

                      {section.description?.includes("Form 1099-S") && (
                        <VStack align="start" spacing={1} mt={4} w='full'>
                          {render1099Items(section, ['Address or legal description', 'Date of Closing', 'Gross Proceeds'])}
                        </VStack>
                      )}

                    </VStack>
                  );
                })}
              </VStack>
            )}

            {form5498Section && (
              <VStack align="start" spacing={1} mt={4}>
                <Text
                  fontSize='lg'
                  as='button'
                  fontWeight='bold'
                  textColor='blue.500'
                  onClick={() => {
                    handleExpandCategory(`Form 5498 Individual Retirement Arrangement Contribution Information`, form5498Section);
                  }}
                >
                  Form 5498 Individual Retirement Arrangement Contribution Information
                </Text>
                <VStack
                  align='start'
                  w='full'
                >
                  <Text fontWeight='bold'>{form5498Section.businessLabel}: </Text>
                  <Text>Trustee's Federal Identification Number (FIN): {form5498Section.businessEin}</Text>
                  <Text>Address: {form5498Section.businessAddress1} {form5498Section.businessAddress2}, {form5498Section.businessCity}, {form5498Section.businessState} {form5498Section.businessZip}</Text>

                  <Text fontWeight='bold'>{form5498Section.individualLabel}: </Text>
                  <Text>Participant's Social Security Number: {form5498Section.individualSsn}</Text>
                  <Text>Address: {form5498Section.individualAddress1} {form5498Section.individualAddress2}, {form5498Section.individualCity}, {form5498Section.individualState} {form5498Section.individualZip}</Text>
                </VStack>

                {['Roth IRA Code', 'IRA Code', 'SEP Code', 'Simple Code', 'Fair Market Value of Account'].map((description) => {
                  const foundItem = form5498Section.items.find((item: any) => item.description === description);

                  return foundItem ? (
                    <React.Fragment key={description}>
                      <HStack
                        justify='space-between'
                        w='full'
                      >
                        <Text
                          textAlign='start'
                          fontWeight='bold'
                        >
                          {foundItem.description}:
                        </Text>
                        <Text
                          color={foundItem.value === 'Checked' ? 'green.500' : foundItem.value === 'Not Checked' ? 'red.500' : ''}>
                          {foundItem.value}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

            {form8889Section && (() => {
              const employerHsaContributions = form8889Section.items.find((item: any) => item.description === "EMPLOYER HSA CONTRIBUTIONS").value;
              const grossHsaContributionsLimit = form8889Section.items.find((item: any) => item.description === "GROSS HSA CONTRIBUTIONS LIMIT").value;

              const getValue = (value: string) => parseFloat(value.replace(/[$,]/g, "")) || 0;

              const contributions = getValue(employerHsaContributions);
              const contributionsLimit = getValue(grossHsaContributionsLimit);

              return (
                <VStack align="start" spacing={1} mt={4} w="full">
                  <Text
                    fontSize='lg'
                    fontWeight='bold'
                    as='button'
                    textColor='blue.500'
                    onClick={() => {
                      handleExpandCategory('Form 8889 Health Savings Account Contributions', form8889Section);
                    }}
                  >
                    Form 8889 Health Savings Account Contributions
                  </Text>
                  {['EMPLOYER HSA CONTRIBUTIONS', 'GROSS HSA CONTRIBUTIONS LIMIT'].map((description) => {
                    const totalValue = calculateTotal([form8889Section], [description]);
                    const foundItem = form8889Section.items.find((item: any) => item.description === description);
                    const hsaColor =
                      description === 'EMPLOYER HSA CONTRIBUTIONS'
                        ? contributions >= contributionsLimit
                          ? 'red'
                          : 'green'
                        : 'default';

                    return foundItem ? (
                      <React.Fragment key={description}>
                        <HStack
                          justify='space-between'
                          w='full'
                          color={hsaColor}
                        >
                          <Text
                            textAlign='start'
                            fontWeight='bold'
                          >
                            {foundItem.description}:
                          </Text>
                          <Text>
                            ${currencyFormat(totalValue)}
                          </Text>
                        </HStack>
                        <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                      </React.Fragment>
                    ) : null;
                  })}
                </VStack>
              );
            })()}

            {scheduleASection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>Schedule A Itemized Deductions</Text>

                {[
                  'TOTAL ITEMIZED DEDUCTIONS',
                  'MEDICAL/DENTAL',
                  'TAXES PAID',
                  'INTEREST PAID',
                  'CHARITABLE CONTRIBUTIONS',
                  'OTHER MISCELLANEOUS',
                  'JOBS AND MISCELLANEOUS',
                  'CASUALTY OR THEFT LOSS',
                ].map((description) => {
                  const subsection = scheduleASection.subsections.find((sub: any) => sub.description === description);
                  let label;

                  switch (description) {
                    case 'TOTAL ITEMIZED DEDUCTIONS':
                      label = 'TOTAL ITEMIZED DEDUCTIONS';
                      break;
                    case 'MEDICAL/DENTAL':
                      label = 'NET MEDICAL DEDUCTION PER COMPUTER';
                      break;
                    case 'TAXES PAID':
                      label = 'SCH A TAX PER COMPUTER';
                      break;
                    case 'INTEREST PAID':
                      label = 'TOTAL INTEREST DEDUCTION PER COMPUTER';
                      break;
                    case 'CHARITABLE CONTRIBUTIONS':
                      label = 'SCH A TOTAL CONTRIBUTIONS PER COMPUTER';
                      break;
                    case 'OTHER MISCELLANEOUS':
                      label = 'OTHER MISC DEDUCTIONS';
                      break;
                    case 'JOBS AND MISCELLANEOUS':
                      label = 'NET LIMITED MISC DEDUCTION PER COMPUTER';
                      break;
                    case 'CASUALTY OR THEFT LOSS':
                      label = 'CASUALTY OR THEFT LOSS';
                      break;
                    default:
                      label = '';
                  }

                  const totalValue = subsection ? calculateTotal([subsection], [label]) : null;

                  return subsection && totalValue > 0 ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory(`Schedule A Itemized Deductions - ${subsection.description}`, subsection);
                        }}
                      >
                        <Text textAlign='start' fontWeight='bold'>
                          {label}:
                        </Text>
                        <Text>
                          ${currencyFormat(totalValue)}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

            {/* Mortgage Interest Section */}
            {mortgageSections?.length > 0 && (
              <VStack align="start" spacing={1} mt={4}>
                {mortgageSections.map((section: any, index: number) => {
                  const interestItem = section.items.find((item: any) => item.description === "Mortgage Interest Received from Payer(s)/Borrower(s)");
                  const principalItem = section.items.find((item: any) => item.description === "Outstanding Mortgage Principle");
                  const originationDateItem = section.items.find((item: any) => item.description === "Mortgage Origination Date");
                  const effectiveInterestRate = calculateMortgageInterestRate(interestItem?.value, principalItem?.value);

                  const originationDate = new Date(originationDateItem?.value);
                  const isFullYear = originationDate.getFullYear() <= new Date().getFullYear() - 1

                  return (
                    <VStack align="start" spacing={1} mt={4} key={index} w='full'>
                      <Text
                        as='button'
                        textColor='blue.500'
                        fontSize='lg'
                        fontWeight='bold'
                        onClick={() => {
                          handleExpandCategory(`Form 1098 Mortgage Interest Statement for ${moment(originationDate).format('MM/DD/YYYY')}`, section);
                        }}
                      >
                        Form 1098 Mortgage Interest Statement for {moment(originationDate).format('MM/DD/YYYY')}
                      </Text>
                      <Text fontWeight='bold'>{section.businessLabel}: </Text>
                      <Text>Recipient's Federal Identification Number (FIN): {section.businessEin}</Text>
                      <Text>Address: {section.businessAddress1} {section.businessAddress2}, {section.businessCity}, {section.businessState} {section.businessZip}</Text>

                      <Text fontWeight='bold'>{section.individualLabel}: </Text>
                      <Text>Payer's Social Security Number: {section.individualSsn}</Text>
                      <Text>Address: {section.individualAddress1} {section.individualAddress2}, {section.individualCity}, {section.individualState} {section.individualZip}</Text>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>

                      {['Mortgage Origination Date', 'Outstanding Mortgage Principle', 'Address of property securing Mortgage'].map((description) => {
                        const foundItem = section.items.find((item: any) => item.description === description);

                        return foundItem ? (
                          <React.Fragment key={description}>
                            <HStack
                              justify='space-between'
                              w='full'
                            >
                              <Text
                                textAlign='start'
                                fontWeight='bold'
                              >
                                {foundItem.description}:
                              </Text>
                              <Text>
                                {foundItem.value}
                              </Text>
                            </HStack>
                            <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                          </React.Fragment>
                        ) : null;
                      })}
                      {isFullYear &&
                        <HStack justify="space-between" w="full">
                          <Text fontWeight='bold'>Estimated Mortgage Rate</Text>
                          <Text color="blue.500" fontWeight="bold">
                            {effectiveInterestRate}
                          </Text>
                        </HStack>}
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </VStack>
                  );
                })}
              </VStack>
            )}

            {refundSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Refund or Amount Owed
                </Text>

                {['REFUND AMOUNT', 'AMOUNT YOU OWE'].map((description) => {
                  const totalValue = calculateTotal([refundSection], [description]);
                  const foundItem = refundSection.items.find((item: any) => item.description === description);

                  return foundItem ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory('Refund or Amount Owed', refundSection);
                        }}
                      >
                        <Text
                          textAlign='start'
                          fontWeight='bold'
                        >
                          {foundItem.description}:
                        </Text>
                        <Text>
                          ${currencyFormat(totalValue)}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

            {/*Adjustments to Income Section*/}
            {adjustmentsToIncomeSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Adjustments to Income
                </Text>
                {['ADJUSTED GROSS INCOME PER COMPUTER', 'TOTAL ADJUSTMENTS PER COMPUTER'].map((description) => {
                  const foundItem = adjustmentsToIncomeSection.items.find((item: any) => item.description === description);

                  return foundItem ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory('Adjustments to Income', adjustmentsToIncomeSection);
                        }}
                      >
                        <Text
                          textAlign='start'
                          fontWeight='bold'
                        >
                          {foundItem.description}:
                        </Text>
                        <Text>
                          {foundItem.value}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

            {form8995Section && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Form 8995 Qualified Business Credit
                </Text>
                {form8995Section.items.map((item: any, index: number) => {
                  const totalValue = calculateTotal([form8995Section], [item.description]);
                  if (totalValue > 0 || item.description === 'TOTAL QUALIFIED BUSINESS LOSS CARRYFORWARD' || item.description === 'QUALIFIED REIT DIV AND PTP LOSS CARRYFORWARD') {
                    return (
                      <Box w='full' key={index}>
                        <HStack
                          as='button'
                          textColor='blue.500'
                          justify='space-between'
                          w='full'
                          onClick={() => {
                            handleExpandCategory('Form 8995 Qualified Business Credit', form8995Section);
                          }}
                        >
                          <Text
                            textAlign='start'
                            fontWeight='bold'
                          >
                            {item.description}:
                          </Text>
                          <Text>
                            ${currencyFormat(totalValue)}
                          </Text>
                        </HStack>
                        <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                      </Box>
                    );
                  }
                  return null;
                })}
              </VStack>
            )}

            {form8863EducationSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Form 8863 - Education Credits (Hope and Lifetime Learning Credits)
                </Text>
                {form8863EducationSection.subsections.map((subsection: any, index: number) => {
                  const totalValue = calculateTotal([subsection], subsection.items.map((item: any) => item.description));
                  if (totalValue > 0) {
                    return (
                      <Box w='full' key={index}>
                        <HStack
                          as='button'
                          textColor='blue.500'
                          justify='space-between'
                          w='full'
                          onClick={() => {
                            handleExpandCategory(`Form 8863 - Education Credits - ${subsection.description}`, subsection);
                          }}
                        >
                          <Text
                            textAlign='start'
                            fontWeight='bold'
                          >
                            {subsection.description}:
                          </Text>
                          <Text>
                            ${currencyFormat(totalValue)}
                          </Text>
                        </HStack>
                        <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                      </Box>
                    );
                  }
                  return null;
                })}
              </VStack>
            )}

            {creditsSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Tax and Credits
                </Text>

                {['TOTAL CREDITS PER COMPUTER'].map((description) => {
                  const totalValue = calculateTotal([creditsSection], [description]);
                  const foundItem = creditsSection.items.find((item: any) => item.description === description);

                  return foundItem ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory('Tax and Credits', creditsSection);
                        }}
                      >
                        <Text
                          textAlign='start'
                          fontWeight='bold'
                        >
                          {foundItem.description}:
                        </Text>
                        <Text>
                          ${currencyFormat(totalValue)}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

            {scheduleSESection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Schedule SE(Self-Employment) Tax
                </Text>
                {['TOTAL SE TAX PER COMPUTER'].map((description) => {
                  const totalValue = calculateTotal([scheduleSESection], [description]);
                  const foundItem = scheduleSESection.items.find((item: any) => item.description === description);

                  return foundItem ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory('Schedule SE(Self-Employment) Tax', scheduleSESection);
                        }}
                      >
                        <Text
                          textAlign='start'
                          fontWeight='bold'
                        >
                          {foundItem.description}:
                        </Text>
                        <Text>
                          ${currencyFormat(totalValue)}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

            {otherTaxesSection && (
              <VStack align="start" spacing={1} mt={4}>
                <Text fontSize='lg' fontWeight='bold'>
                  Other Taxes
                </Text>

                {['TOTAL OTHER TAXES PER COMPUTER'].map((description) => {
                  const totalValue = calculateTotal([otherTaxesSection], [description]);
                  const foundItem = otherTaxesSection.items.find((item: any) => item.description === description);

                  return foundItem ? (
                    <React.Fragment key={description}>
                      <HStack
                        as='button'
                        textColor='blue.500'
                        justify='space-between'
                        w='full'
                        onClick={() => {
                          handleExpandCategory('Other Taxes', otherTaxesSection);
                        }}
                      >
                        <Text
                          textAlign='start'
                          fontWeight='bold'
                        >
                          {foundItem.description}:
                        </Text>
                        <Text>
                          ${currencyFormat(totalValue)}
                        </Text>
                      </HStack>
                      <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                    </React.Fragment>
                  ) : null;
                })}
              </VStack>
            )}

          </ModalBody>
          <ModalFooter>
            <Button colorScheme="brandScheme" variant="ghost" onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {
        selectedSection && (
          <DetailsModal
            isOpen={isDetailOpen}
            onClose={onDetailClose}
            title={selectedTitle}
            section={selectedSection}
          />
        )
      }
    </>
  );
}

const DetailsModal = ({ isOpen, onClose, title, section }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
    <ModalOverlay/>
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton/>
      <ModalBody>
        <VStack align="stretch">
          {section?.subsections && section.subsections.map((subsection: any, subsectionIndex: number) => (
            <Box key={subsectionIndex}>
              <Box flex="1" textAlign="left" fontWeight="bold">
                {subsection?.description}
              </Box>
              {subsection?.items && subsection?.items.map((item: any, itemIndex: number) => (
                <Box key={itemIndex}>
                  <HStack justifyContent="space-between" pl={2}>
                    <Text>{item.description}:</Text>
                    <Text fontWeight="semibold">{item.value}</Text>
                  </HStack>
                  <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
                </Box>
              ))}
            </Box>
          ))}

          {section?.items.map((item: any, index: number) => (
            <Box key={index}>
              <HStack justifyContent="space-between">
                <Text>{item.description}:</Text>
                <Text fontWeight="semibold">{item.value}</Text>
              </HStack>
              <Divider mb={1} borderColor="gray.300" borderWidth={1} borderStyle="dashed"/>
            </Box>
          ))}
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="brand" onClick={onClose}>Close</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);


interface TaxBracketProps {
  income: number;
  filingStatus: "Single" | "Married Filing Joint" | "Head Of Household";
}

const TaxBracketRange: React.FC<TaxBracketProps> = ({ income, filingStatus }) => {
  const brackets: any = {
    Single: [
      { upper: 11600, rate: 0.10 },
      { upper: 47150, rate: 0.12 },
      { upper: 100525, rate: 0.22 },
      { upper: 191950, rate: 0.24 },
      { upper: 243725, rate: 0.32 },
      { upper: 609350, rate: 0.35 },
      { upper: Infinity, rate: 0.37 },
    ],
    "Married Filing Joint": [
      { upper: 23200, rate: 0.10 },
      { upper: 94300, rate: 0.12 },
      { upper: 201050, rate: 0.22 },
      { upper: 383900, rate: 0.24 },
      { upper: 487450, rate: 0.32 },
      { upper: 731200, rate: 0.35 },
      { upper: Infinity, rate: 0.37 },
    ],
    "Head Of Household": [
      { upper: 16550, rate: 0.10 },
      { upper: 63100, rate: 0.12 },
      { upper: 100500, rate: 0.22 },
      { upper: 191950, rate: 0.24 },
      { upper: 243700, rate: 0.32 },
      { upper: 609350, rate: 0.35 },
      { upper: Infinity, rate: 0.37 },
    ],
  };

  const filingBrackets = brackets[filingStatus] || [];

  if (!filingBrackets.length) {
    return (
      <Box mt={1} mb={4}>
        <Text color="red.500">
          Tax bracket information is unavailable.
        </Text>
      </Box>
    );
  }

  // Find the current and next tax bracket for the given income
  const currentBracketIndex = filingBrackets.findIndex((b: any) => income <= b.upper);
  console.log('ccc', filingBrackets);
  const currentBracket = filingBrackets[currentBracketIndex];
  const previousBracket = filingBrackets[currentBracketIndex - 1] || { upper: 0 };
  const nextBracket = filingBrackets[currentBracketIndex + 1] || { upper: Infinity };

  const range = nextBracket.upper;

  // Calculate the total range for the chart
  const minIncome = previousBracket.upper;
  const maxIncome = currentBracket?.upper;


  const distanceToPrevious = income - previousBracket.upper;
  const distanceToNext = currentBracket.upper - income;


  // Chart configuration
  const chartOptions: any = {
    chart: {
      type: "bar",
      height: 150,
      stacked: true,
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "100%",
        distributed: false,
      },
    },
    yaxis: {
      show: false,
    },
    colors: ["#FFC107", "#4CAF50", "#F44336"],
    xaxis: {
      type: "numeric",
      min: minIncome - range * 0.1, // Better spacing on the left
      max: maxIncome + range * 0.2, // Allow extra space for the next bracket
      labels: {
        formatter: (val: number) => `$${val.toLocaleString()}`,
      },
    },
    annotations: {
      xaxis: [
        {
          x: income,
          borderColor: "#000",
          strokeDashArray: 0,
          strokeWidth: 6,
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
  };

  const chartSeries = [
    {
      name: "Previous Bracket",
      data: [minIncome],
    },
    {
      name: "Your Income",
      data: [income],
    },
    {
      name: "Next Bracket",
      data: [currentBracket.upper],
    },
  ];

  return (
    <Box mt={1} mb={4}>
      <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={120}/>
      <Text mt={1} textAlign="center">
        {distanceToPrevious > 0 &&
          `You are $${distanceToPrevious.toLocaleString()} above the previous bracket.`}
      </Text>
      <Text mt={1} textAlign="center">
        {distanceToNext > 0
          ? `You are $${distanceToNext.toLocaleString()} away from the next tax bracket.`
          : "You are in the highest tax bracket."}
      </Text>
    </Box>
  );
};

export default SmarttaxReportCardModal;
