import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import Select from 'react-select';
import { getPastMonthsList } from '../../../../libraries/dateFilterOptions';

interface BankStatementModalProps {
  isLoading: boolean;
  isOpen: boolean,
  onClose: () => void,
  account: any,
  onDownload: (accountId: string, index: number) => Promise<void>
}

const InvestmentStatementModal: React.FC<BankStatementModalProps> = ({
                                                                       isLoading,
                                                                       isOpen,
                                                                       onClose,
                                                                       onDownload,
                                                                       account
                                                                     }) => {
  const twoYearsOfMonthsList = getPastMonthsList(2);
  const [selectedIndex, setSelectedIndex] = useState<{ value: number, label: string } | null>(twoYearsOfMonthsList[0]);

  const download = async () => {
    await onDownload(account ? account.accountId : '', selectedIndex?.value || 1);
    onClose();
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} isCentered>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Download statement</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <HStack>
            <Text fontWeight='500'>Institution Name: </Text>
            <Text>{account?.institutionName}</Text>
          </HStack>
          <HStack>
            <Text fontWeight='500'>Account Name: </Text>
            <Text>{account?.accountName}</Text>
          </HStack>
          <HStack>
            <Text fontWeight='500'>Account Number: </Text>
            <Text>{account?.accountNumber}</Text>
          </HStack>
          <FormControl mt="12px" hidden={account?.isManual}>
            <FormLabel>Month</FormLabel>
            <Select
              isLoading={isLoading}
              value={selectedIndex}
              isDisabled={account?.isManual}
              placeholder="Select a month"
              onChange={setSelectedIndex}
              options={twoYearsOfMonthsList}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} onClick={() => download()} isLoading={isLoading}
                  loadingText='Downloading...'>Download</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InvestmentStatementModal;
