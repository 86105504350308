// Chakra imports
import { Box, Flex, Grid, Image, Link, Text, useColorModeValue, VStack } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import LinkedAccounts from "views/admin/profile/components/LinkedAccounts";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React, { useState } from "react";
import { useAuth } from "../../../auth-context/auth.context";
import LinkedCanopyAccounts from "./components/LinkedCanopyAccounts";
import { validateRole } from "../../../libraries/utils";

export default function Overview() {
  const {user} = useAuth();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue("gray.500", "white");
  const [connectedIns, setConnectedIns] = useState(false);

  const onConnected = (flag) => {
    setConnectedIns(flag);
  }

  return (<Box pt={{base: "130px", md: "80px", xl: "80px"}}>
    {/* Main Fields */}
    <Grid
      templateColumns={{
        base: "none", lg: "repeat(2, 1fr)",
      }}
      templateRows={{
        base: "none", lg: "1fr",
      }}
      gap={{base: "20px", xl: "20px"}}>
      <Banner
        gridArea='1 / 1 / 2 / 2'
        banner={banner}
        avatar={avatar}
        name={`${user?.firstName} ${user?.lastName}`}
        company={user?.fullName}
        job={validateRole(user?.token)}
        email={user?.email}
        management={user?.management}
        posts='17'
        followers='9.7k'
        following='274'
      />
      {
        validateRole(user?.token) !== '' && user?.management === 'insurance' ? <LinkedCanopyAccounts
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name={user?.fullName}
          job={validateRole(user?.token)}
          posts='17'
          followers='9.7k'
          following='274'
        /> : <LinkedAccounts
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          avatar={avatar}
          name={user?.fullName}
          job={validateRole(user?.token)}
          posts='17'
          followers='9.7k'
          following='274'
          connected={connectedIns}
          onConnected={onConnected}
        />
      }
      {/*<Storage*/}
      {/*  gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}*/}
      {/*  used={25.6}*/}
      {/*  total={50}*/}
      {/*/>*/}
      {/*<Upload*/}
      {/*  gridArea={{*/}
      {/*    base: "3 / 1 / 4 / 2",*/}
      {/*    lg: "1 / 3 / 2 / 4",*/}
      {/*  }}*/}
      {/*  minH={{ base: "auto", lg: "420px", "2xl": "365px" }}*/}
      {/*  pe='20px'*/}
      {/*  pb={{ base: "100px", lg: "20px" }}*/}
      {/*/>*/}
    </Grid>
    {
      connectedIns && <Flex
        justify='center' align='center' direction='column'>
        <VStack
          width={{base: '100%', md: '80%'}}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            textAlign='center'
            mt="24px">
            Trusted and Secure
          </Text>
          <Text
            color={textColorSecondary}
            fontSize="xl"
            fontWeight='500'
            lineHeight='140%'
            textAlign='center'
            mb="4px">
            Smartdata is where trust meets technology. We never see or store your username and password. We have the
            following features to always safeguard your data using bank level encryption.
          </Text>
        </VStack>
        <Grid
          width={{base: '100%', md: '80%'}}
          mt='20px'
          templateColumns={{
            base: 'none', md: 'repeat(2, 1fr)',
          }}
          templateRows={{
            base: 'none', lg: '1fr',
          }}>
          <Flex justify='start' align='center' direction='column' margin='12px'>
            <Image src={require('../../../assets/img/financials/safe-vault.png')}
                   w={{base: '50%', md: '200px'}}
                   borderRadius='full'
                   h="auto" />
            <Text color={textColorPrimary}
                  fontSize="2xl"
                  fontWeight='bold'
                  textAlign='center'
                  mt='20px'
                  mb="4px">
              Best-in-Class Security
            </Text>
          </Flex>
          <Flex justify='start' align='center' direction='column' margin='12px'>
            <Image src={require('../../../assets/img/financials/encryption.png')}
                   w={{base: '50%', md: '200px'}}
                   h="auto" />
            <Text color={textColorPrimary}
                  fontSize="2xl"
                  fontWeight='bold'
                  textAlign='center'
                  mt='20px'
                  mb="4px">
              End-to-End Encryption
            </Text>
          </Flex>
        </Grid>
      </Flex>
    }
    {/*<Grid*/}
    {/*  mb='20px'*/}
    {/*  templateColumns={{*/}
    {/*    base: "1fr",*/}
    {/*    lg: "repeat(2, 1fr)",*/}
    {/*    "2xl": "1.34fr 1.62fr 1fr",*/}
    {/*  }}*/}
    {/*  templateRows={{*/}
    {/*    base: "1fr",*/}
    {/*    lg: "repeat(2, 1fr)",*/}
    {/*    "2xl": "1fr",*/}
    {/*  }}*/}
    {/*  gap={{ base: "20px", xl: "20px" }}>*/}
    {/*  <Projects*/}
    {/*    gridArea='1 / 2 / 2 / 2'*/}
    {/*    banner={banner}*/}
    {/*    avatar={avatar}*/}
    {/*    name={user?.fullName}*/}
    {/*    job={user?.accountType}*/}
    {/*    posts='17'*/}
    {/*    followers='9.7k'*/}
    {/*    following='274'*/}
    {/*  />*/}
    {/*  <General*/}
    {/*    gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}*/}
    {/*    minH='365px'*/}
    {/*    pe='20px'*/}
    {/*  />*/}
    {/*  <Notifications*/}
    {/*    used={25.6}*/}
    {/*    total={50}*/}
    {/*    gridArea={{*/}
    {/*      base: "3 / 1 / 4 / 2",*/}
    {/*      lg: "2 / 1 / 3 / 3",*/}
    {/*      "2xl": "1 / 3 / 2 / 4",*/}
    {/*    }}*/}
    {/*  />*/}
    {/*</Grid>*/}
  </Box>);
}
