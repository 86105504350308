/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../../../auth-context/auth.context';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Column, useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import Card from '../../../components/card/Card';
import TableSpinner from '../../../components/spinner/TableSpinner';
import moment from 'moment';
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
  deleteAdvisor,
  getAdvisorsByFirmId,
  remindInvitationAdvisor,
  resetPasswordRequest,
  updateAdvisorRole
} from '../../../api/user-service';
import { ClientType } from "../../../types/user";
import DeleteAdvisorModal from "../../admin/modals/components/DeleteAdvisorModal";
import ResetPasswordModal from "../../admin/modals/components/ResetPasswordModal";
import { COUNT_PER_PAGE } from "../../../config/constant";
import { useDebounce } from "../../../libraries/useDebounce";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination as useAjnaPagination
} from "@ajna/pagination";
import { mode } from "@chakra-ui/theme-tools";

const CLIENT_COLUMNS: Column<ClientType>[] | any = [
  {
    Header: 'NAME',
    accessor: 'fullName',
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
  },
  // {
  //   Header: 'MANAGEMENT',
  //   accessor: 'management',
  // },
  {
    Header: 'ROLE',
    accessor: 'firmRole',
  },
  {
    Header: 'INVITED AT',
    accessor: 'createdAt',
  },
  {
    Header: 'STATUS',
    accessor: 'accepted',
  },
  {
    Header: 'OTHER',
    accessor: 'other',
  },
];

const AdvisorList: React.FC = () => {
  const { user, selectedClient, setSelectedClient } = useAuth();
  const toast = useToast();

  const bgPagination = useColorModeValue('brand.500', 'brand.400');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const textColorSecondary = 'secondaryGray.600';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [advisors, setAdvisors] = useState<ClientType[]>([]);
  const [total, setTotal] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAdvisor, setSelectedAdvisor] = useState<any>({
    userId: undefined,
    firstName: undefined,
    lastName: undefined
  });
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetClose } = useDisclosure();
  const debouncedSearch = useDebounce(search, 1000);

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = useAjnaPagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  const tableInstance = useTable(
    {
      columns: CLIENT_COLUMNS,
      data: advisors,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const fetchAdvisors = async () => {
    setIsLoading(true);
    try {
      const { advisors } = await getAdvisorsByFirmId(user.firmId, pageSize, offset, search);
      setAdvisors(advisors);
    } catch (error) {
      console.log('error', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setSelectedClient(null);
    if (user) {
      fetchAdvisors();
    }
  }, [user, currentPage, offset, debouncedSearch]);

  const removeAdvisor = async ({
                                 toUserId
                               }: {
    toUserId: number
  }) => {
    if (!selectedAdvisor?.userId) return;
    setIsDeleting(true);
    deleteAdvisor(selectedAdvisor?.userId, toUserId)
      .then(() => {
        toast({
          title: 'Delete Advisor',
          description: `${selectedAdvisor?.firstName} ${selectedAdvisor?.lastName} has been removed.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchAdvisors();
      })
      .catch((err) => {
        toast({
          title: 'Delete Advisor',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsDeleting(false);
      })
  }

  const resendInvitationEmail = (userId: number) => {
    remindInvitationAdvisor(userId)
      .then(() => {
        toast({
          title: 'Email reminder has been sent.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Updates the clients state
        setAdvisors(prevClients =>
          prevClients.map(client =>
            client.userId === userId
              ? { ...client, resentInvitation: true }
              : client
          )
        );
      })
      .catch((err) => {
        toast({
          title: 'Remind Invitation',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  const resetPassword = async ({ isAutoGenerated, password, askToChange }: {
    isAutoGenerated: boolean,
    password?: string,
    askToChange?: boolean,
  }) => {
    await resetPasswordRequest(selectedAdvisor.userId, isAutoGenerated, password, askToChange)
      .then(() => {
        toast({
          title: 'Reset Password',
          description: `The password of ${selectedAdvisor.firstName} ${selectedAdvisor.lastName} has been reset.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Reset Password',
          description: err?.response?.data?.message || err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  type Role = 'admin' | 'supervisor' | 'user';

  interface RoleMenuProps {
    currentRole: Role;
    onRoleChange: (newRole: Role) => void;
  }

  const roleColors: Record<Role, string> = {
    admin: 'red',
    supervisor: 'gray',
    user: 'gray',
  };

  const RoleMenu: React.FC<RoleMenuProps> = ({ currentRole, onRoleChange }) => {
    return (
      <Menu>
        <MenuButton as={Button} size="sm" colorScheme={roleColors[currentRole]} bg='gray.200'>
          {currentRole.charAt(0).toUpperCase() + currentRole.slice(1)}
        </MenuButton>
        <MenuList>
          {(['supervisor', 'user'] as Role[]).map((role) => (
            <Tooltip
              key={role}
              label={
                role === 'supervisor'
                  ? 'Supervisor can see all prospects of the firm.'
                  : 'User can only see prospects invited by them.'
              }
              aria-label={`${role} tooltip`}
              fontSize="sm"
              placement="right"
            >
              <MenuItem
                onClick={() => currentRole !== role && onRoleChange(role)}
                isDisabled={currentRole === role} // Disable if the role is the same
              >
                {role.charAt(0).toUpperCase() + role.slice(1)}
              </MenuItem>
            </Tooltip>
          ))}
        </MenuList>
      </Menu>
    );
  };

  const updateUserRole = async (user: any, role: Role) => {
    updateAdvisorRole(user.userId, role)
      .then(() => {
        toast({
          title: `${user.firstName} ${user.lastName}'s role has changed to ${role.charAt(0).toUpperCase() + role.slice(1)}.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Updates the advisor's role
        setAdvisors(prevClients =>
          prevClients.map(client =>
            client.userId === user.userId
              ? { ...client, firmRole: role }
              : client
          )
        );
      })
      .catch((err) => {
        toast({
          title: 'Update Role',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <VStack>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
          opacity={isLoading ? 0.7 : 1}
        >
          <TableSpinner loading={isLoading} />
          <Flex px="25px" direction={{ base: 'column', md: 'row' }}
                justify={{ base: 'start', md: 'space-between' }}
                mb="20px" align={{ base: 'start', md: 'center' }} gap={2}>
            <HStack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon color="gray.300" />}
                />
                <Input
                  type="input"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </HStack>
            <Text fontSize='sm' color={textColorSecondary}>You can add an advisor in Settings / Firm
              tab.</Text>
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            size="sm"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    bgColor={row.original.userId === selectedClient?.userId ? '#dddddd' : 'transparent'}
                    _hover={{ background: '#dddddd55' }}
                  >
                    {row.cells.map((cell, index) => {
                      let data: ReactNode;
                      if (cell.column.Header === 'NAME') {
                        data = (
                          <HStack>
                            <Avatar
                              src={cell.row.original?.avatarUrl}
                              name={`${cell.row.original.firstName} ${cell.row.original.lastName}`}
                              w="32px"
                              h="32px"
                              size='12px'
                            />
                            <Box>
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                {cell.row.original.firstName} {cell.row.original.lastName}
                              </Text>
                              <Text
                                color={textColorSecondary}
                                fontSize="sm"
                                fontWeight="500"
                              >
                                {cell.value}
                              </Text>
                            </Box>
                          </HStack>
                        );
                      } else if (cell.column.Header === 'EMAIL') {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === 'ROLE') {
                        data = (
                          <RoleMenu
                            currentRole={cell.value}
                            onRoleChange={async (newRole) => {
                              await updateUserRole(cell.row.original, newRole);
                            }}
                          />
                        );
                      } else if (cell.column.Header === 'INVITED AT') {
                        const now = moment(new Date());
                        const expiryDate = moment(cell.value);
                        const daysUntilExpiry = 90 - now.diff(expiryDate, 'days');

                        let displayValue;

                        if (daysUntilExpiry === 1 && !cell.row.original.accepted) {
                          displayValue = `Expires in ${daysUntilExpiry} day`;
                        } else if (daysUntilExpiry > 1 && daysUntilExpiry <= 90 && !cell.row.original.accepted) {
                          displayValue = `Expires in ${daysUntilExpiry} days`;
                        } else {
                          displayValue = moment(cell.value).format('MMMM DD, YYYY');
                        }

                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {displayValue}
                          </Text>
                        );
                      } else if (cell.column.Header === 'STATUS') {
                        const now = moment(new Date());
                        const expiryDate = moment(cell.row.original.clientSince);
                        const daysUntilExpiry = now.diff(expiryDate, 'days');

                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            <Badge
                              colorScheme={cell.value ? 'green' : 'red'}>{cell.value ? 'ACCEPTED' : (!cell.value && daysUntilExpiry > 90) ? 'INVITATION EXPIRED' : 'NOT ACCEPTED'}
                            </Badge>
                          </Text>
                        );
                      } else {
                        data = (
                          <Menu>
                            <MenuButton colorScheme='green' as={Button}
                                        rightIcon={<ChevronDownIcon />} size='sm'>
                              Actions
                            </MenuButton>
                            <MenuList>
                              <MenuItem as='a' href={`mailto:${cell.row.original.email}`}>Email
                                Advisor</MenuItem>
                              <MenuItem onClick={() => resendInvitationEmail(cell.row.original.userId)}>
                                Remind Invitation
                              </MenuItem>
                              <MenuItem onClick={() => {
                                onResetOpen();
                                setSelectedAdvisor({
                                  userId: cell.row.original.userId,
                                  firstName: cell.row.original.firstName,
                                  lastName: cell.row.original.lastName,
                                });
                              }}>Reset Password</MenuItem>
                              <MenuItem onClick={() => {
                                onOpen();
                                setSelectedAdvisor({
                                  userId: cell.row.original.userId,
                                  firstName: cell.row.original.firstName,
                                  lastName: cell.row.original.lastName,
                                });
                              }}>Delete Advisor</MenuItem>
                            </MenuList>
                          </Menu>

                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer
              align="center"
              justify="center"
              p={4}
              gap={2}
              w="full"
            >
              <PaginationPrevious
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              > Prev
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    w={10}
                    jumpSize={5}
                  />
                }
              >
                {pages.map((page) => (
                  <PaginationPage
                    key={`pagination_page_${page}`}
                    page={page}
                    w={10}
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    _hover={{
                      bg: 'brand.600'
                    }}
                    _current={{
                      bg: 'brand.600',
                      fontSize: 'sm',
                      w: 10
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              >Next</PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Card>
      </VStack>
      <DeleteAdvisorModal advisor={selectedAdvisor} isOpen={isOpen} onClose={() => {
        onClose();
        setSelectedAdvisor({
          userId: undefined,
          firstName: undefined,
          lastName: undefined
        })
      }} onDelete={removeAdvisor} />
      <ResetPasswordModal isOpen={isResetOpen} onClose={onResetClose} user={selectedAdvisor}
                          onReset={resetPassword} />
    </Box>
  );
};

export default AdvisorList;
