import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react';
import { AccountType, Management } from '../../../../config/constant';
import US_STATES from '../../../../config/us-sate.json';
import { isValidEmail, isValidUSPhone } from "../../../../libraries/utils";
import moment from "moment";

export type Gender = 'Female' | 'Male' | 'Non-binary' | 'Unknown' | '';
export type MaritalStatus =
  | 'Married'
  | 'Single'
  | 'Divorced'
  | 'Widowed'
  | 'Life Partner'
  | 'Separated'
  | 'Unknown'
  | '';

interface UserAddModalProps {
  isOpen: boolean,
  onClose: () => void,
  user: any,
  onSave: (e: {
    firstName: string,
    middleName: string,
    lastName: string,
    nickName: string,
    dateOfBirth: string,
    gender: Gender,
    maritalStatus: MaritalStatus,
    phoneNumber: string,
    userRole: string,
    management: string,
    addressLine1: string,
    city: string,
    state: string,
    postalCode: string,
    companyName: string,
    ownBusiness: boolean,
    hasBusinessLiabilities?: boolean,
    estimatedBusinessValue?: string | null,
    estimatedDebt?: string | null,
    spouseName?: string,
    spouseDob?: string | null,
    note?: string,
  }) => Promise<void>
}

const UpdateClientModal: React.FC<UserAddModalProps> = ({ isOpen, onClose, onSave, user }) => {
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nickName, setNickName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState<Gender>('');
  const [maritalStatus, setMaritalStatus] = useState<MaritalStatus>('');
  const [spouseName, setSpouseName] = useState('');
  const [spouseDob, setSpouseDob] = useState('');
  const [ownBusiness, setOwnBusiness] = useState(false);
  const [hasBusinessLiabilities, setHasBusinessLiabilities] = useState(false);
  const [estimatedBusinessValue, setEstimatedBusinessValue] = useState<
    number | ''
  >('');
  const [estimatedDebt, setEstimatedDebt] = useState<number | ''>('');

  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userRole, setUserRole] = useState<AccountType>(AccountType.CLIENT);
  const [management, setManagement] = useState<Management>(Management.FINANCIAL);
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [note, setNote] = useState('');

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Utility function to convert empty values to null
  const replaceEmptyWithNull = (value: any) => {
    return value === '' ? null : value;
  };

  const handleDateChange = (
    e: ChangeEvent<HTMLInputElement>,
    isSpouse: boolean = false
  ) => {
    const value = e.target.value;
    // Remove any characters that are not digits or hyphens
    let cleanedValue = value.replace(/[^\d/]/g, '');

    // Automatically insert slashes at the correct positions for MM/DD/YYYY format
    cleanedValue = cleanedValue
      // Insert first slash after two digits
      .replace(/^(\d{2})(\d)/, '$1/$2')
      // Insert second slash after two more digits
      .replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3')
      // Limit the length to the maximum date format (MM/DD/YYYY)
      .substring(0, 10);

    // Update date value
    if (isSpouse) {
      setSpouseDob(cleanedValue);
    } else {
      setDob(cleanedValue);
    }
  };

  const save = async () => {
    let message = null;
    const isValidDob = moment(dob, 'MM/DD/YYYY', true).isValid();

    if (!firstName) {
      message = 'First name is required.';
    } else if (!lastName) {
      message = 'Last name is required.';
    } else if (!email) {
      message = 'Email is required.';
    } else if (!isValidEmail(email)) {
      message = 'Invalid email address.';
    } else if (phoneNumber && !isValidUSPhone(phoneNumber)) {
      message = 'Please input valid phone number.';
    } else if (!isValidDob) {
      message = 'Date of Birth is invalid';
    } else if (!addressLine1.trim()) {
      message = 'Address Line1 is required';
    } else if (!city.trim()) {
      message = 'City is required';
    } else if (!state.trim()) {
      message = 'State is required';
    } else if (!postalCode.trim()) {
      message = 'Zip Code is required';
    }

    if (message) {
      setErrorMessage(message);
      toast({
        title: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      setIsSaving(true);
      const phoneNumberDigits = phoneNumber.replace(/\D/g, '');
      const capitalizedFirstName = capitalizeFirstLetter(firstName.trim());
      const capitalizedLastName = capitalizeFirstLetter(lastName.trim());

      await onSave({
        addressLine1,
        city,
        companyName,
        firstName: capitalizedFirstName,
        lastName: capitalizedLastName,
        dateOfBirth: dob ? moment(dob, 'MM/DD/YYYY').format('YYYY-MM-DD') : '',
        management,
        middleName,
        nickName,
        phoneNumber: phoneNumberDigits ? `+1${phoneNumberDigits}` : '',
        postalCode,
        state,
        userRole,
        note,
        gender,
        maritalStatus,
        ownBusiness,
        hasBusinessLiabilities: ownBusiness ? hasBusinessLiabilities : false,
        spouseName: maritalStatus === 'Married' ? spouseName : '',
        spouseDob: maritalStatus === 'Married' && spouseDob ? moment(spouseDob, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        estimatedBusinessValue: ownBusiness ? replaceEmptyWithNull(estimatedBusinessValue) : null,
        estimatedDebt: ownBusiness && hasBusinessLiabilities ? replaceEmptyWithNull(estimatedDebt) : null,
      });
      setIsSaving(false);
      handleClose();
    }
  }

  useEffect(() => {
    setDefaultUserInfo();
  }, [user]);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let onlyNums = value.replace(/\D/g, '');
    let formattedNumber;
    if (onlyNums.length <= 3) {
      formattedNumber = onlyNums;
    } else if (onlyNums.length <= 6) {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    } else {
      formattedNumber = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }

    setPhoneNumber(formattedNumber);
    setErrorMessage(null);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setErrorMessage(null);
  }

  const handleCompanyName = (value: string) => {
    setCompanyName(value);
    setErrorMessage(null);
  }

  const handleClose = () => {
    onClose();
    setDefaultUserInfo();
    setErrorMessage(null);
  }

  const setDefaultUserInfo = () => {
    setFirstName(user?.firstName);
    setMiddleName(user?.middleName);
    setLastName(user?.lastName);
    setNickName(user?.nickName);
    setDob(
      user?.dateOfBirth
        ? moment(user?.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : ''
    );
    setEmail(user?.email);
    setPhoneNumber(user?.phoneNumber ? user?.phoneNumber.replace(/\+1/g, '') : '');
    setCompanyName(user?.fullName);
    setAddressLine1(user?.addressLine1);
    setCity(user?.city);
    setState(user?.state);
    setPostalCode(user?.postalCode);
    setUserRole(AccountType.CLIENT);
    setManagement(Management.FINANCIAL);
    setGender(user?.gender);
    setMaritalStatus(user?.maritalStatus);
    setSpouseName(user?.spouseName);
    setSpouseDob(
      user?.spouseDob
        ? moment(user?.spouseDob, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : ''
    );
    setOwnBusiness(user?.ownBusiness);
    setHasBusinessLiabilities(user?.hasBusinessLiabilities);
    setEstimatedDebt(user?.estimatedDebt);
    setEstimatedBusinessValue(user?.estimatedBusinessValue);
    setNote(user?.note);
  }

  const formatNumberWithCommas = (value: number | string) => {
    if (!value || value === '') return '';
    return parseFloat(value.toString()).toLocaleString('en-US');
  };

  const handleNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const inputValue = event.target.value.replace(/[^\d]/g, ''); // Remove non-numeric characters
    const formattedValue = inputValue ? parseInt(inputValue, 10) : '';
    switch (type) {
      case 'estimatedBusinessValue':
        setEstimatedBusinessValue(formattedValue);
        return;
      case 'estimatedDebt':
        setEstimatedDebt(formattedValue);
        return;
      default:
        return;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleClose()} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Prospect</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid
            templateColumns={useBreakpointValue({ base: '1fr', md: 'repeat(3, 1fr)' })}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input placeholder='John' value={firstName}
                     onChange={(e) => {
                       setFirstName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>Middle Name</FormLabel>
              <Input placeholder='Fortune (Optional)' value={middleName}
                     onChange={(e) => {
                       setMiddleName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input placeholder='Doe' value={lastName}
                     onChange={(e) => {
                       setLastName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
          </Grid>
          <Grid
            mt="12px"
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
            gap={useBreakpointValue({ base: 4, md: 2 })}
          >
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input placeholder='you@example.com' type="email" colorScheme="brandScheme" value={email} readOnly
                     onChange={(e) => handleEmailChange(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input colorScheme="brandScheme" value={phoneNumber}
                     type="tel"
                     placeholder="(201)-555-0123 (Optional)"
                     onChange={(e) => handlePhoneNumberChange(e)} />
            </FormControl>
            <FormControl>
              <FormLabel>Nick Name</FormLabel>
              <Input placeholder='nickname (Optional)' value={nickName}
                     onChange={(e) => {
                       setNickName(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input placeholder='Company (Optional)' colorScheme="brandScheme" value={companyName}
                     onChange={(e) => handleCompanyName(e.target.value)} marginBottom='8px' />
            </FormControl>
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <Select
                value={gender}
                onChange={(event) => {
                  setGender(event.target.value as Gender);
                }}
              >
                <option key={0} value="">
                  Select a gender...
                </option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Non-binary">Non-binary</option>
                <option value="Unknown">Prefer not to say</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Marital Status</FormLabel>
              <Select
                value={maritalStatus}
                onChange={(event) => {
                  setMaritalStatus(event.target.value as MaritalStatus);
                }}
              >
                <option key={0} value="">
                  Select a status...
                </option>
                <option value="Married">Married</option>
                <option value="Single">Single</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
                <option value="Life Partner">Life Partner</option>
                <option value="Separated">Separated</option>
                <option value="Unknown">Prefer not to say</option>
              </Select>
            </FormControl>
            <FormControl hidden={maritalStatus !== 'Married'}>
              <FormLabel>Spouse Name</FormLabel>
              <Input
                isRequired={true}
                placeholder="Jane Doe"
                value={spouseName}
                onChange={(event) => {
                  setSpouseName(event.target.value);
                }}
              />
            </FormControl>
            <FormControl hidden={maritalStatus !== 'Married'}>
              <FormLabel>Spouse Dob</FormLabel>
              <Input
                isRequired={true}
                placeholder="MM/DD/YYYY"
                value={spouseDob}
                type="text"
                onChange={(event) => {
                  handleDateChange(event, true);
                }}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Date of Birth</FormLabel>
              <Input placeholder='MM/DD/YYYY' colorScheme="brandScheme" value={dob} type='text'
                     onChange={(e) => handleDateChange(e)} marginBottom='8px' />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Address</FormLabel>
              <Input placeholder='Address' colorScheme="brandScheme" value={addressLine1}
                     onChange={(e) => {
                       setAddressLine1(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>City</FormLabel>
              <Input placeholder='City' colorScheme="brandScheme" value={city}
                     onChange={(e) => {
                       setCity(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>State/Province</FormLabel>
              <Select
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  setErrorMessage(null);
                }}
              >
                <option key={0} value=''>
                  Select a state...
                </option>
                {US_STATES.map((usState) => (
                  <option key={usState.abbreviation} value={usState.abbreviation}>
                    {usState.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Zip/Postal Code</FormLabel>
              <Input placeholder='Postal Code' colorScheme="brandScheme" value={postalCode}
                     maxLength={5}
                     onChange={(e) => {
                       setPostalCode(e.target.value);
                       setErrorMessage(null);
                     }} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Country</FormLabel>
              <Input value='United States' colorScheme="brandScheme" readOnly />
            </FormControl>

            <GridItem mt={2} colSpan={2}>
              <HStack>
                <Checkbox
                  colorScheme="brand"
                  isChecked={ownBusiness}
                  onChange={() => setOwnBusiness((prev) => !prev)}
                />
                <Text fontWeight="600" fontSize="md">
                  Do you own a business?
                </Text>
              </HStack>
            </GridItem>

            {ownBusiness && (
              <GridItem colSpan={2}>
                <HStack mt={2} justify="start" w="full">
                  <Checkbox
                    colorScheme="brand"
                    isChecked={hasBusinessLiabilities}
                    onChange={() =>
                      setHasBusinessLiabilities((prev) => !prev)
                    }
                  />
                  <Text fontWeight="500" fontSize="md">
                    Do You Have Any Liabilities / Debt?
                  </Text>
                </HStack>
                <Grid
                  templateColumns="repeat(2, 1fr)"
                  w="full"
                  gap={4}
                  mt="4px"
                >
                  {hasBusinessLiabilities && (
                    <GridItem colSpan={{ base: 2, md: 1 }}>
                      <FormLabel
                        fontWeight="500"
                        color="gray.700"
                      >
                        Estimated total debt/liabilities (optional)
                      </FormLabel>

                      <InputGroup>
                        <InputLeftAddon>$</InputLeftAddon>
                        <Input
                          type="text"
                          placeholder="Enter estimated total debt/liabilities"
                          value={
                            estimatedDebt !== ''
                              ? formatNumberWithCommas(estimatedDebt)
                              : ''
                          }
                          onChange={(e) =>
                            handleNumberChange(e, 'estimatedDebt')
                          }
                        />
                      </InputGroup>
                    </GridItem>
                  )}

                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <FormLabel
                      fontWeight="500"
                      color="gray.700"
                    >
                      Estimated Business Value (optional)
                    </FormLabel>

                    <InputGroup>
                      <InputLeftAddon>$</InputLeftAddon>
                      <Input
                        type="text"
                        placeholder="Enter estimated business value"
                        value={
                          estimatedBusinessValue !== ''
                            ? formatNumberWithCommas(estimatedBusinessValue)
                            : ''
                        }
                        onChange={(e) =>
                          handleNumberChange(e, 'estimatedBusinessValue')
                        }
                      />
                    </InputGroup>
                  </GridItem>
                </Grid>
              </GridItem>
            )}

            <GridItem colSpan={{ base: 1, md: 2 }}>
              <FormControl>
                <FormLabel>Note</FormLabel>
                <Textarea placeholder='Internal note about prospect' value={note}
                          rows={3}
                          onChange={(e) => {
                            setNote(e.target.value);
                          }} />
              </FormControl>
            </GridItem>
          </Grid>
          <FormControl>
            {/*<FormLabel>Management</FormLabel>*/}
            {/*<RadioGroup value={management} onChange={(e: Management) => setManagement(e)}>*/}
            {/*  <HStack>*/}
            {/*    <Radio colorScheme="brandScheme"*/}
            {/*           value={Management.FINANCIAL}>{Management.FINANCIAL.toUpperCase()}</Radio>*/}
            {/*    <Radio colorScheme="brandScheme"*/}
            {/*           value={Management.INSURANCE}>{Management.INSURANCE.toUpperCase()}</Radio>*/}
            {/*  </HStack>*/}
            {/*</RadioGroup>*/}
            {errorMessage ? (
              <FormHelperText color='red'>
                {errorMessage}
              </FormHelperText>
            ) : null}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" mr={3} onClick={() => save()} isLoading={isSaving} isDisabled={isSaving}
                  loadingText='Saving'>Save</Button>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UpdateClientModal;
