import React, { useState } from "react";

// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  keyframes,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure,
  useEventListener,
  useToast,
  VStack
} from "@chakra-ui/react";
import Header from "./Header";
import { FaChevronUp } from "react-icons/fa";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { isValidEmail } from "../../libraries/utils";
import { sendMessage } from "../../api/user-service";
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ReactPlayer from "react-player";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { BsPatchCheckFill } from "react-icons/bs";
import { FiChevronsDown, FiChevronsRight } from "react-icons/fi";

const smartFinanceFeatures = [
  'Add unlimited users from your firm',
  'Invite a prospect with an email link or QR code branded with your firm’s logo',
  'Add Connect Screen to to new client onboarding form using the smartdata™ website widget',
  'Includes new client onboarding form, software integrations, and smartportfolio™ report to show portfolio holdings, tax category, and allocation with total smartfees™ (fund fees, advisor fees, and smarttax™ fee) and smartcast™ for benchmarking portfolio gross and portfolio net against the S&P500 and forecasting.',
  '1 smartportfolio™ data connection=1 financial institution (unlimited accounts per financial institution)',
  'Download investment statements',
  'Real-time market data and investment research',
  'Record or upload an intro video to the Connect Screen to new client onboarding form for your prospect to "Meet your advisor"',
  'Add a calendar link so your prospect can book a time after they connect their account',
];

const smartTaxFeatures = [
  'Add unlimited users from your firm',
  'Fully digital secure workflow',
  'Your prospect or client can fill out and digitally sign the IRS form in under 20 seconds',
  'Each smarttax™ report is delivered within 10 days, guaranteed',
  'Data includes: Three years of Tax Returns and Wage & Income, including Forms 1040, K1s, and 1120, directly from the IRS. Total tax liability, Any installment agreements, Effective Tax Rate, Net Capital Gain (Gain or Loss Carryforward)',
  'Access your prospect or client’s smarttax™ report for the following 3 years',
  'Direct connection to the IRS to pull accurate tax data',
  '1 smarttax™ data connection=1 tax order',
  'Rely on smarttax™ for speed and certainty when getting tax data',
  'Need it expedited? Order a Same-Day smarttax™ for $200 or a Next-Day smarttax™ for $150',
];

export default function LandingPage() {
  const history = useHistory();
  const toast = useToast();
  const [showButton, setShowButton] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [isIntro, setIsIntro] = useState(true);

  const priceFontSize = useBreakpointValue({base: '12px', md: '20px'});
  const ChevronsIcon = useBreakpointValue({base: FiChevronsDown, md: FiChevronsRight});

  const pulse = keyframes`
    from {
      transform: scale(1.2);
      opacity: 1;
    }
    to {
      transform: scale(4);
      opacity: 0;
    }
  `;

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const goToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEventListener('scroll', handleScroll);

  const handleContactUs = async () => {
    try {
      let message = '';
      if (!contactName) {
        message = 'Name is required.';
      } else if (!contactEmail) {
        message = 'Email is required.';
      } else if (!isValidEmail(contactEmail)) {
        message = 'Invalid email address.';
      } else if (!contactMessage) {
        message = 'Message is required';
      }
      if (message) {
        toast({
          title: message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      setIsSendingMessage(true);
      const res = await sendMessage(contactName, contactEmail, contactMessage);
      if (res.success) {
        toast({
          title: res.message,
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
        setContactName('');
        setContactEmail('');
        setContactMessage('');
      }
    } catch (e) {
      toast({
        title: e?.response?.data?.message || e.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsSendingMessage(false);
    }
  }

  return (
    <Box>
      <Box
        bgImage={require('../../assets/img/layout/home_header_blur.jpg')}
        bgRepeat="no-repeat"
        bgAttachment="fixed"
        bgSize="cover"
        boxSizing="border-box"
        m="0"
        p="0"
        position="relative">
        <Header />
        <VStack
          mx='auto'
          justify='start'
          align='start'
          maxW={{base: '100%', md: '80%'}}
          pb={{base: 50, md: 100, xl: 280}}
          color='secondaryGray.200'
          pt={{base: 12, xl: 20}}
          px={{base: 4, xl: 20}}>
          <Text
            as="h1"
            fontSize={{base: '4xl', lg: '6xl'}}
            fontWeight='1000'
            textAlign='left'
            letterSpacing='-1px'
            lineHeight={{base: '40px', lg: '64px'}}
          >
            AI-powered finance and tax data with human insights to scale your AUM.
          </Text>
          <Flex
            py={{base: 8, xl: 12}}
            direction={{base: 'column-reverse', xl: 'row'}}
            align={{base: 'center', xl: 'start'}}
            w='100%'
            gap={8}
          >
            <Box w={{base: '100%', xl: '50%'}}>
              <Text fontSize={{base: '2xl', md: '4xl'}} fontWeight='500' letterSpacing='-1px'
                    lineHeight={{base: '28px', md: '40px'}}>
                Meet our smartdata™ workflow
              </Text>
              <List spacing={1} mt={4} pl={10} styleType='disc' fontSize={{base: 'xl', md: '2xl'}} fontWeight='500'>
                <ListItem>
                  Send your firm’s branded new client onboarding form through an email invite, link, or custom QR code
                </ListItem>
                <ListItem>
                  Get access to your prospect's finance and tax data for portfolio review <b>3x faster</b>
                </ListItem>
                <ListItem>
                  Have your prospect go from prospect to client <b>2x faster</b>
                </ListItem>
                <ListItem>
                  Scale your firm with more prospect meetings in less time and no manual data entry.
                </ListItem>
              </List>
            </Box>
            <Box
              w={{base: '95%', xl: '50%'}}
              h={{lg: 'auto'}}
              position="relative"
              overflow="hidden"
              borderRadius="20px"
              boxShadow='0px 0px 16px 0px #9eda6b'
            >
              <ReactPlayer
                light={<img src={require('../../assets/img/layout/intro_video_preview.jpg')} alt='Intro Video' />}
                url='https://clarifees-files-prod.s3.amazonaws.com/smartdata/smartdata_intro.mp4'
                width='100%'
                height='100%'
                controls
                playing
                playIcon={
                  <div style={{
                    position: 'absolute', // Position it over the player
                    top: '50%',          // Center vertically
                    left: '50%',         // Center horizontally
                    transform: 'translate(-50%, -50%)' // Adjust for the icon's size
                  }}>
                    <MdOutlinePlayCircleFilled style={{color: 'green', opacity: 0.8, fontSize: '4rem'}} />
                  </div>
                }
                style={{alignSelf: 'center'}} />
            </Box>
          </Flex>
          <Button
            WebkitTextSizeAdjust='100%'
            WebkitTapHighlightColor='rgba(0,0,0,0)'
            boxSizing='inherit'
            px='40px'
            py='28px'
            minW={{base: '200px', md: '260px'}}
            WebkitBackfaceVisibility='hidden'
            transition='.3s'
            lineHeight='1'
            border='2px solid'
            fontSize='xl'
            fontWeight={700}
            borderRadius='full'
            letterSpacing={.6}
            boxShadow='3px 4px 18px 0px #9eda6b'
            fontStyle='normal'
            bg='brand.500'
            rightIcon={<ArrowForwardIcon />}
            borderColor='brand.500'
            _hover={{
              bg: '#486927',
              boxShadow: 'none',
            }}
            color='white'
            onClick={() => history.push('/auth/signup')}
          >
            GET STARTED
          </Button>
        </VStack>
      </Box>

      <VStack id='about-us' mt={{base: '80px', md: '100px'}} mb='60px'>
        <Text as='h2' fontSize={{base: '4xl', md: '5xl'}} fontWeight='900' color='brand.500' textAlign='center'>
          About Us
        </Text>

        <Grid
          p='20px'
          maxW={{base: '100%', md: '80%'}}
          templateColumns={{
            base: 'none', md: 'repeat(2, 1fr)',
          }}
          gap={{base: 12, md: 20}}
        >
          {/*<GridItem colSpan={{base: '3', md: '1'}}*/}
          {/*          display="flex"*/}
          {/*          justifyContent="center"*/}
          {/*          alignItems="center"*/}
          {/*>*/}
          {/*  <Image*/}
          {/*    src={require('../../assets/img/layout/about-1.jpg')}*/}
          {/*    maxW={{base: '80%', md: '100%'}}*/}
          {/*    h="auto"*/}
          {/*    position="relative"*/}
          {/*    borderRadius="30% 70% 70% 30%/30% 30% 70% 70%"*/}
          {/*  />*/}

          {/*  <Center*/}
          {/*    position="absolute"*/}
          {/*    w="60px"*/}
          {/*    h="60px"*/}
          {/*    borderRadius="50%"*/}
          {/*    bgColor="#FF8402"*/}
          {/*    color="#fff"*/}
          {/*    cursor="pointer"*/}
          {/*    zIndex={3}*/}
          {/*    onClick={() => {*/}
          {/*      setIsIntro(false);*/}
          {/*      onOpen();*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <MdPlayArrow size='32px' />*/}
          {/*  </Center>*/}
          {/*  <Box*/}
          {/*    position="absolute"*/}
          {/*    w="28px"*/}
          {/*    h="28px"*/}
          {/*    borderStyle="solid"*/}
          {/*    borderColor="white"*/}
          {/*    borderWidth="1px"*/}
          {/*    borderRadius="full"*/}
          {/*    bg="transparent"*/}
          {/*    animation={`${pulse} 2s linear infinite`}*/}
          {/*    zIndex={2}*/}
          {/*  />*/}
          {/*  <Box*/}
          {/*    position="absolute"*/}
          {/*    w="32px"*/}
          {/*    h="32px"*/}
          {/*    borderStyle="solid"*/}
          {/*    borderColor="white"*/}
          {/*    borderWidth="1px"*/}
          {/*    borderRadius="full"*/}
          {/*    bg="transparent"*/}
          {/*    animation={`${pulse} 2s 0.1s infinite`}*/}
          {/*    zIndex={1}*/}
          {/*  />*/}
          {/*</GridItem>*/}
          <GridItem colSpan={{base: '3', md: '2'}}>

            <Text fontSize='2xl' fontWeight='400'>
              Founded in Omaha, NE. Gone are the days when your client brought you physical copies of investment
              statements and tracking down tax returns.
              With <Box as="span" fontWeight='600'>Smartdata</Box>’s revolutionary patent-pending
              workflow, with just a click of a button, you can access your prospective client’s financial and tax data
              to do more with less and scale your firm.
            </Text>

            <Divider my='40px' />
            <Text fontSize='xl' fontWeight='600' textAlign='start'>
              “$90 trillion in assets is set to change hands over the next 20 years.”
            </Text>
            <Text fontSize='xl' fontWeight='400' textAlign='end'>
              –Consulting firm Knight Frank
            </Text>

          </GridItem>
        </Grid>
        <Box maxW={{base: '100%', md: '70%'}}>
          <Grid
            mt='40px'
            gap='20px'
            templateColumns={{
              base: 'none', md: 'repeat(2, 1fr)',
            }}
            templateRows={{
              base: 'none', lg: '1fr',
            }}>
            <Flex justify='start' align='center' direction='column' margin='20px'>
              <Image src={require('../../assets/img/financials/safe-vault.png')}
                     w={{base: '50%', md: '200px'}}
                     borderRadius='full'
                     h="auto" />
              <Text
                fontSize="2xl"
                fontWeight='bold'
                textAlign='center'
                color='brand.500'
                mt='20px'
                mb="4px">
                Best-in-Class Security
              </Text>
            </Flex>
            <Flex justify='start' align='center' direction='column' margin='20px'>
              <Image src={require('../../assets/img/financials/encryption.png')}
                     w={{base: '50%', md: '200px'}}
                     borderRadius='full'
                     h="auto" />
              <Text
                fontSize="2xl"
                fontWeight='bold'
                textAlign='center'
                color='brand.500'
                mt='20px'
                mb="4px">
                End-to-End Encryption
              </Text>
            </Flex>
          </Grid>
        </Box>
      </VStack>

      <Flex
        bgImage={require('../../assets/img/layout/about-2.jpg')}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgAttachment="fixed"
        bgSize="cover"
        boxSizing="border-box"
        position="relative"
        justify='center'
        align='center'
      >
        <VStack
          w={{base: '100%', md: '80%'}}
          align='center'
          justify='center'
          zIndex={1}
          h='360px'
        >
          <Text fontSize={{base: '3xl', md: '5xl'}} fontWeight='600' color='white' textAlign='center' mb='20px'>
            No more expensive dinners, sporting events, time away from your friends and family.
          </Text>
          <Button
            WebkitTextSizeAdjust='100%'
            WebkitTapHighlightColor='rgba(0,0,0,0)'
            boxSizing='inherit'
            px='40px'
            py='28px'
            minW={{base: '200px', md: '260px'}}
            WebkitBackfaceVisibility='hidden'
            transition='.3s'
            lineHeight='1'
            border='2px solid'
            fontSize='xl'
            fontWeight={700}
            borderRadius='full'
            letterSpacing={.6}
            boxShadow='3px 4px 18px 0px #9eda6b'
            fontStyle='normal'
            bg='brand.400'
            borderColor='brand.400'
            color='white'
            _hover={{
              bg: 'transparent',
              boxShadow: 'none',
            }}
            onClick={() => history.push('/auth/signup')}
          >
            GET STARTED
          </Button>
        </VStack>
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          bg="blackAlpha.700"
        />
      </Flex>

      <VStack id='product' mt={{base: '80px', md: '100px'}} mb='60px' px='20px'>
        <Text as='h2' fontSize={{base: '4xl', md: '5xl'}} fontWeight='900' color='brand.500' textAlign='center'>
          Product
        </Text>
        <VStack maxW={{base: '100%', md: '80%'}} bg='logo.green' color='white' borderRadius='16px' p={4} align='start'
                gap={2}>
          <HStack>
            <Box minW='16px' h='16px' color='yellow'>
              <BsPatchCheckFill size="100%" />
            </Box>
            <Text fontSize={{base: 'md', md: 'lg'}} fontWeight='500' textAlign='start'>
              All core smartdata™ features, including link invite and prospect management, retrieval of financial data, and
              more.
            </Text>
          </HStack>
          <HStack>
            <Box minW='16px' h='16px' color='yellow'>
              <BsPatchCheckFill size="100%" />
            </Box>
            <Text fontSize={{base: 'md', md: 'lg'}} fontWeight='500' textAlign='start'>
              Includes new client onboarding form, software integrations, and smartportfolio™ report to show portfolio
              holdings, tax category, and allocation with total smartfees™ (fund fees, advisor fees, and smarttax fee)
              and smartcast™ for benchmarking portfolio gross and portfolio net against the S&P500 and forecasting.
            </Text>
          </HStack>
          <HStack>
            <Box minW='16px' h='16px' color='yellow'>
              <BsPatchCheckFill size="100%" />
            </Box>
            <Text fontSize={{base: 'md', md: 'lg'}} fontWeight='500' textAlign='start'>
              Wealthbox, Redtail, and Salesforce integration
            </Text>
          </HStack>
          <Grid
            w={{base: '100%', md: '40%'}}
            templateColumns='repeat(3, 1fr)'
            justifyItems='center'
            alignItems='center'
            gap={{base: 4, md: 8}}
            alignSelf="flex-end"
            px='32px'>
            <Image src={require('../../assets/img/financials/wealthbox.png')} />
            <Image src={require('../../assets/img/financials/redtail-orion-logo.png')} />
            <Image src={require('../../assets/img/financials/salesforce-logo.png')} />
          </Grid>
        </VStack>
        <Text fontSize={{base: 'md', md: 'xl'}} textAlign='center' px='20px' maxW={{base: '100%', md: '80%'}}>
          Capture part of that transfer by having a workflow ready that the younger generation prefers. We connect with
          over 10,000 financial institutions.
        </Text>
        <Flex
          w='full'
          maxW={{base: '100%', md: '80%'}}
          direction={{base: 'column', md: 'row'}}
          align='center'
          justify='space-around'
          gap={8}
          p='20px'>
          <VStack spacing={2}>
            <PhotoProvider>
              <PhotoView src={require('../../assets/img/layout/qr-code.png')}>
                <Image
                  src={require('../../assets/img/layout/qr-code.png')}
                  border="32px solid #3A9E3E"
                  borderRadius='16px'
                  cursor='pointer'
                  w='auto'
                  maxH={{base: 'auto', md: '280px'}}
                />
              </PhotoView>
            </PhotoProvider>
            <Text fontSize='xl' fontWeight='500' textAlign='center' maxW='320px'>Invite prospect using email invite,
              link, or branded QR code</Text>
          </VStack>
          <ChevronsIcon color='green' size='48px' />
          <VStack spacing={2}>
            <PhotoProvider>
              <Flex direction={{base: 'column', xl: 'row'}} gap={2}>
                <PhotoView src={require('../../assets/img/layout/smartfinance-connect.png')}>
                  <Image
                    src={require('../../assets/img/layout/smartfinance-connect.png')}
                    border="8px solid #3A9E3E"
                    borderRadius='16px'
                    cursor='pointer'
                    w='auto'
                    maxH={{base: 'auto', md: '180px', xl: '280px'}}
                  />
                </PhotoView>
                <PhotoView src={require('../../assets/img/layout/smarttax-connect.png')}>
                  <Image
                    src={require('../../assets/img/layout/smarttax-connect.png')}
                    border="8px solid #3A9E3E"
                    borderRadius='16px'
                    cursor='pointer'
                    w='auto'
                    maxH={{base: 'auto', md: '180px', xl: '280px'}}
                  />
                </PhotoView>
              </Flex>
            </PhotoProvider>
            <Text fontSize='xl' fontWeight='500' textAlign='center' maxW='500px'>
              Prospect fills out the smartdata™ onboarding form and connects all financial and/or tax accounts
            </Text>
          </VStack>
        </Flex>

        <Flex
          w='full'
          maxW={{base: '100%', md: '80%'}}
          direction={{base: 'column', md: 'row'}}
          align='center'
          justify='space-around'
          p='20px'
          gap={8}
        >
          <VStack spacing={2}>
            <PhotoProvider>
              <Flex
                direction={{base: 'column', md: 'row'}}
                gap={2}
                wrap='wrap'
                justify="center"
                align="center">
                <PhotoView src={require('../../assets/img/layout/prospect-list.png')}>
                  <Image
                    src={require('../../assets/img/layout/prospect-list.png')}
                    border="8px solid #3A9E3E"
                    borderRadius='16px'
                    cursor='pointer'
                    w='auto'
                    maxH={{base: 'auto', md: '100px', xl: '180px'}}
                  />
                </PhotoView>
                <PhotoView src={require('../../assets/img/layout/smartportfolio.png')}>
                  <Image
                    src={require('../../assets/img/layout/smartportfolio.png')}
                    border="8px solid #3A9E3E"
                    borderRadius='16px'
                    cursor='pointer'
                    w='auto'
                    maxH={{base: 'auto', md: '100px', xl: '180px'}}
                  />
                </PhotoView>
                <PhotoView src={require('../../assets/img/layout/smarttax.png')}>
                  <Image
                    src={require('../../assets/img/layout/smarttax.png')}
                    border="8px solid #3A9E3E"
                    borderRadius='16px'
                    cursor='pointer'
                    w='auto'
                    maxH={{base: 'auto', md: '100px', xl: '180px'}}
                  />
                </PhotoView>
              </Flex>
            </PhotoProvider>
            <Text fontSize='xl' fontWeight='500' textAlign='center' maxW='500px'>
              Prospect's onboarding form, finance and tax data is delivered using Smartdata's AI aggregation
            </Text>
          </VStack>
          <ChevronsIcon color='green' size='48px' />
          <VStack minH='280px' minW='280px' align='center' justify='center'>
            <Text fontSize='6xl' fontWeight='800' textAlign='center' lineHeight='60px'>
              SCALE
            </Text>
            <Text fontSize='6xl' fontWeight='800' textAlign='center' lineHeight='70px'>
              YOUR
            </Text>
            <Text fontSize='9xl' color='logo.green' fontWeight='900' textAlign='center' lineHeight='100px'
                  letterSpacing={2}>
              AUM
            </Text>
          </VStack>
        </Flex>
      </VStack>

      <VStack id='pricing' mb='60px' p={{base: '20px', md: 0}}>
        <Text as='h2' fontSize={{base: '4xl', md: '5xl'}} fontWeight='900' color='brand.500' textAlign='center'>
          Pricing
        </Text>
        <Text fontSize='xl' color='gray.500' textAlign='center' w={{base: '90%', md: '60%'}}>
          Pick Your Tier. Scale Your Firm.
        </Text>
        <Text fontSize='lg'>
          Platform Fee $500/firm per month
        </Text>
        <Text color='gray.700'>Includes: Onboarding Customization, Training, Support, Integrations and portfolio data entry for SmartUpload™</Text>
        <Box maxW={{base: '100%', md: '80%'}}>
          <Table variant="simple" colorScheme="gray">
            <TableCaption placement="top">
              <Text fontSize='lg' fontWeight='bold'>
                smartportfolio™
              </Text>
            </TableCaption>
            <Thead bg='gray.200'>
              <Tr>
                <Th px={{base: 2, md: '16px'}}>Tiers</Th>
                <Th textAlign="center" p={{base: '0', md: '16px'}}>Lite</Th>
                <Th textAlign="center" p={{base: '0', md: '16px'}}>Pro</Th>
                <Th textAlign="center" p={{base: '0', md: '16px'}}>Elite</Th>
              </Tr>
            </Thead>
            <Tbody fontSize={{base: 'sm', md: 'lg'}}>
              <Tr>
                <Td px={{base: 1, md: '16px'}}>Monthly Premium</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$175/Firm</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$375/Firm</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$600/Firm</Td>
              </Tr>
              <Tr>
                <Td px={{base: 1, md: '16px'}}>smartportfolio™ data connections</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">5</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">15</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">30</Td>
              </Tr>
              <Tr>
                <Td px={{base: 1, md: '16px'}}>Additional data connections</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$35/Each</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$25/Each</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$20/Each</Td>
              </Tr>
            </Tbody>
          </Table>

          <Table variant="simple" colorScheme="gray">
            <Thead bg='gray.200'>
              <Tr>
                <Th px={{base: 2, md: '16px'}}>Features</Th>
              </Tr>
            </Thead>
            <Tbody>
              {smartFinanceFeatures.map((feature, idx) => (
                <Tr key={idx}>
                  <Td fontSize={{base: 'md', md: 'lg'}} fontWeight='600' px={{base: 0, md: '16px'}}>{feature}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box textAlign="center" my={4}>
            <Text fontSize="xl">
              Need more?{' '}
              <Link href="mailto:hello@smartdatawealth.com" color="brand.500" fontWeight="bold">
                Contact Us
              </Link>{' '}
              for Enterprise Pricing
            </Text>
          </Box>

          <Table variant="simple" colorScheme="gray" mt={8}>
            <TableCaption placement="top">
              <Text fontSize='lg' fontWeight='bold'>
                smarttax™
              </Text>
            </TableCaption>
            <Thead bg='gray.200'>
              <Tr>
                <Th px={{base: 2, md: '16px'}}>Tiers</Th>
                <Th textAlign="center" p={{base: '0', md: '16px'}}>Lite</Th>
                <Th textAlign="center" p={{base: '0', md: '16px'}}>Pro</Th>
                <Th textAlign="center" p={{base: '0', md: '16px'}}>Elite</Th>
              </Tr>
            </Thead>
            <Tbody fontSize={{base: 'sm', md: 'lg'}} fontWeight='500'>
              <Tr>
                <Td px={{base: 1, md: '16px'}}>Monthly Premium</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$500/Firm</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$1,350/Firm</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$2,250/Firm</Td>
              </Tr>
              <Tr>
                <Td px={{base: 1, md: '16px'}}>smarttax™ data connections</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">5</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">15</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">30</Td>
              </Tr>
              <Tr>
                <Td px={{base: 1, md: '16px'}}>Additional data connections</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$100/Order</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$90/Order</Td>
                <Td p={1} color='gray.600' fontWeight='bold' textAlign="center">$75/Order</Td>
              </Tr>
            </Tbody>
          </Table>
          <Table variant="simple" colorScheme="gray">
            <Thead bg='gray.200'>
              <Tr>
                <Th px={{base: 2, md: '16px'}} colSpan={4}>Features</Th>
              </Tr>
            </Thead>
            <Tbody>
              {smartTaxFeatures.map((feature, idx) => (
                <Tr key={idx}>
                  <Td fontSize={{base: 'md', md: 'lg'}} fontWeight='600' px={{base: 0, md: '16px'}}>{feature}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box textAlign="center" my={4}>
            <Text fontSize="xl">
              Need more?{' '}
              <Link href="mailto:hello@smartdatawealth.com" color="brand.500" fontWeight="bold">
                Contact Us
              </Link>{' '}
              for Enterprise Pricing
            </Text>
          </Box>
        </Box>
      </VStack>

      <VStack id='security' mb='60px' w='full'>
        <Text as='h2' fontSize={{base: '4xl', md: '5xl'}} fontWeight='900' color='brand.500' textAlign='center'>
          Security
        </Text>
        <VStack
          bg='#E5ECF6'
          w='full'
          p={{base: '20px', md: 0}}
          py={{base: 4, md: 12}}
          gap={4}
        >
          <Flex
            w='full'
            maxW={{base: '100%', md: '80%'}}
            direction={{base: 'column', sm: 'row'}}
            gap={{base: 4, md: 8}}
            justify='space-around'
            align='center'>
            <Image
              src={require("../../assets/img/certified-soc2.png")}
              h={{base: '140px', md: '180px', lg: '240px'}}
            />
            <Image
              src={require("../../assets/img/iso-27001-certification.png")}
              h={{base: '140px', md: '180px', lg: '240px'}}
            />
          </Flex>
          <Text
            maxW={{base: '100%', md: '80%'}}
            fontSize={{base: 'lg', md: 'xl'}}
            textAlign='center'>
            Smartdata is SOC 2 and ISO compliant. SOC 2 is an auditing procedure that ensures service providers securely
            manage data to protect the interests of the organization and the privacy of its clients. Additionally, ISO
            certification demonstrates that Smartdata adheres to international standards for quality management and
            information security. Smartdata users or those conducting due diligence may request a copy of our SOC 2
            (Type II) and ISO reports by contacting their account manager or <Link href="mailto:hello@smartdatawealth.com"
                                                                                   isExternal
                                                                                   color="blue.500">hello@smartdatawealth.com</Link>.
          </Text>
        </VStack>
      </VStack>

      <VStack id='faq' mb='60px' p={{base: '20px', md: 0}}>
        <Text as='h2' fontSize={{base: '4xl', md: '5xl'}} fontWeight='900' color='brand.500' textAlign='center'>
          FAQ
        </Text>
        <Accordion allowToggle w={{base: '100%', md: '80%'}}>
          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>How do I sign up for Smartdata?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                Go to <Link color='brand.500' href='https://www.smartdatawealth.com'>www.smartdatawealth.com</Link> and
                click sign up. Enter in your firm's details and then start your free 14 day free trial.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>Can I add advisors under my account?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>Yes you can invite advisors in your firm to join your Smartdata.</Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>How do I connect my CRM and which CRMs do you work with?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                We currently work with Wealthbox and Redtail, with more coming soon. When you login to your Smartdata
                you will see the CRM icons at the top. Select your CRM. Enter in your login credentials and Smartdata
                will automatically connect to your CRM.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>How do I send an invite to a prospect?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                You can manually send an invite from Smartdata by selecting Add a prospect (only a name and an email is
                required) or if you have linked your CRM you can tag your contact in your CRM with "smartdata" and we
                will automatically send the invite for you. Yes, magic.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>How does a prospect send me their information?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                The prospect logs into their brokerage account on their own (user permission based). Once the prospect
                has successfully connected their account Smartdata will pull the data for you.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>
                    How will I know if a prospective prospect has uploaded their information?
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                You will receive an email when the prospect connects their account and when the
                portfolio data is ready to be reviewed.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>How do I create a QR code and link?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                Under smartleads in your Smartdata you will be able to view and copy your custom branded QR code and
                link. Use that on your marketing materials to get more qualified prospects and grow your AUM.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>How do I upload my advisor photo and firm's logo?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                Under settings you can add your photo. The owner of your Smartdata is only able to do add the firm's
                logo. If you do not see a logo make sure to have the owner of your Smartdata upload the firm's
                logo.
              </Text>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton py={4}>
                <Box as="span" flex='1' textAlign='left'>
                  <Text fontSize='xl' fontWeight='400'>How do I contact support?</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text color='gray.700'>
                Please use the below form to send a message or reach out to <Link href='mailto:hello@smartdatawealth.com'
                                                                                  color='brand.500'>hello@smartdatawealth.com</Link> with
                any help you and your firm need.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>

      <VStack id='contact' mb='60px' p={{base: '20px', md: 0}}>
        <Text as='h2' fontSize={{base: '4xl', md: '5xl'}} fontWeight='900' color='brand.500' textAlign='center'>
          Contact Us
        </Text>
        <Grid templateColumns='repeat(2, 1fr)' py={{base: '20px', md: '40px'}} gap={4}
              w={{base: '100%', md: '80%'}}>
          <GridItem colSpan={{base: 2, md: 1}}>
            <Text>
              Have Questions? Get in Touch with Us Today!
            </Text>
            <FormControl mt='16px' isRequired>
              <FormLabel>Your Name</FormLabel>
              <Input placeholder='Name' value={contactName}
                     _focus={{borderColor: 'brand.500'}}
                     onChange={(e) => {
                       setContactName(e.target.value);
                     }} />
            </FormControl>
            <FormControl mt='16px' isRequired>
              <FormLabel>Email</FormLabel>
              <Input placeholder='Email' value={contactEmail}
                     _focus={{borderColor: 'brand.500'}}
                     onChange={(e) => {
                       setContactEmail(e.target.value);
                     }} />
            </FormControl>
            <FormControl mt='16px' isRequired>
              <FormLabel>Your Message</FormLabel>
              <Textarea placeholder='Message' value={contactMessage}
                        _focus={{borderColor: 'brand.500'}}
                        rows={7}
                        onChange={(e) => {
                          setContactMessage(e.target.value);
                        }} />
            </FormControl>

            <Button colorScheme='brand' mt='20px' onClick={handleContactUs} isLoading={isSendingMessage}
                    isDisabled={isSendingMessage}>Send Message</Button>
          </GridItem>
          <GridItem colSpan={{base: 2, md: 1}} maxW='500' pl={{base: 0, md: '80px'}}>
            <Image src={require("../../assets/img/layout/contact.jpg")} />
            <Text mt='20px' fontWeight='500' fontSize='lg'>Looking for help?</Text>
            <Text mt='12px' fontSize='md'>Email us at <Link href='mailto:hello@smartdatawealth.com'
                                                            color='brand.500'>hello@smartdatawealth.com</Link></Text>
            <Divider mt='20px' />
            <Text mt='20px' color='gray.400'>Smartdata is owned and operated by Smartdata Wealth Inc.</Text>
          </GridItem>
        </Grid>

      </VStack>

      <Flex
        direction={'column'}
        align='center'
        as='footer'
        bg='brand.600'
        p='20px'
        color='white'
      >
        <Grid
          templateColumns='repeat(4, 1fr)'
          maxW={{base: '100%', md: '80%'}}
          pt='20px'
          pb='32px'
          gap={8}
        >
          <GridItem colSpan={{base: 4, md: 1}}>
            <Box>
              <Image src={require("../../assets/img/logo-white.png")} alt='Logo' w={{base: '120px', md: '200px'}} />
              <Text>
                <span style={{fontWeight: 600}}>Smartdata</span> is a cutting-edge financial technology firm delivering
                high-speed workflow solutions for financial advisors.
              </Text>
            </Box>
            <HStack gap={2} mt={2}>
              <Link href='https://www.linkedin.com/company/smartdata-finance' target='_blank'>
                <Image
                  src={require('../../assets/img/linkedin2x.png')}
                  alt='linkedin'
                  borderRadius='full'
                  boxSize='32px'
                  cursor='pointer'
                  _hover={{
                    bg: 'whiteAlpha.100'
                  }}
                />
              </Link>
              <Link href='https://www.instagram.com/smartdatawealth.com/' target='_blank'>
                <Image
                  src={require('../../assets/img/instagram2x.png')}
                  alt='instagram'
                  borderRadius='full'
                  boxSize='32px'
                  cursor='pointer'
                  _hover={{
                    bg: 'whiteAlpha.100'
                  }}
                />
              </Link>
            </HStack>
          </GridItem>
          <GridItem colSpan={{base: 4, md: 1}} pt='12px'>
            <VStack align='start'>
              <Text fontWeight='700'>Product</Text>
              <Link href="/" _hover={{textDecoration: 'underline'}}>Home</Link>
              <Link href="/auth/signup" _hover={{textDecoration: 'underline'}}>Register as an
                Advisor</Link>
              <Link href="/auth/login" _hover={{textDecoration: 'underline'}}>Log In</Link>
            </VStack>
          </GridItem>
          <GridItem colSpan={{base: 4, md: 1}} pt='12px'>
            <VStack align='start'>
              <Text fontWeight='700'>Send Message</Text>
              <Link href='mailto:hello@smartdatawealth.com'>hello@smartdatawealth.com</Link>
            </VStack>
          </GridItem>
          <GridItem colSpan={{base: 4, md: 1}} pt='12px'>
            <VStack align='start'>
              <Text fontWeight='700'>Resources</Text>
              <Link href="#pricing" _hover={{textDecoration: 'underline'}}>Pricing</Link>
              <Link href="#about-us" _hover={{textDecoration: 'underline'}}>About Us</Link>
              <Link href="/terms-of-service" as='a' target='_blank' _hover={{textDecoration: 'underline'}}>
                Terms of Service
              </Link>
              <Link href="/privacy-policy" as='a' target='_blank' _hover={{textDecoration: 'underline'}}>
                Privacy Policy
              </Link>
            </VStack>
          </GridItem>
        </Grid>
        <Box
          w='full'
          borderTopWidth='1px'
          borderTopColor='gray.100'
          pt='20px'
          maxW={{base: '100%', md: '80%'}}
        >
          <Text color='white' fontSize='md'>
            &copy; {new Date().getFullYear()} Smartdata Wealth Inc. All Rights reserved.
          </Text>
        </Box>
      </Flex>

      {
        showButton && (
          <IconButton
            icon={<FaChevronUp />}
            isRound
            size="lg"
            position="fixed"
            bottom={{base: 4, md: 12}}
            right={{base: 4, md: 12}}
            boxShadow='3px 4px 18px 0px #9EDA6B'
            colorScheme='brand'
            color="white"
            transition='1.3s'
            zIndex="99"
            opacity={showButton ? 1 : 0}
            onClick={goToTop}
            aria-label='go-to-top'
          />
        )
      }
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered size='4xl'>
        <ModalOverlay />
        <ModalContent pb={8}>
          <ModalHeader>Smartdata {isIntro ? 'Intro' : 'Product'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <video width="100%" height="auto" autoPlay controls>
              <source
                src={isIntro ? 'https://clarifees-files-prod.s3.amazonaws.com/smartdata/smartdata_intro.mp4' : 'https://clarifees-files-prod.s3.amazonaws.com/smartdata/smartdata_product.mp4'}
                type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
