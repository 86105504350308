import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
import { CirclesWithBar } from 'react-loader-spinner';
import { useAuth } from "../../../../auth-context/auth.context";
import { downloadTranscript, summaryReport } from "../../../../api/smarttax-service";
import { currencyFormat } from "../../../../libraries/utils";
import moment from "moment/moment";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import { IoIosBook, IoMdDownload, IoMdEye } from "react-icons/io";
import TranscriptModal from "./TranscriptModal";
import SmarttaxReportCardModal from "./SmarttaxReportCardModal";

interface SmartPortfolioProps {
  isOpen: boolean,
  onClose: () => void,
  orderId: number | undefined,
}


const tableColumns: any = [
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Form',
    accessor: 'form',
  },
  {
    Header: 'Year',
    accessor: (row: any) => row.period.split('-')[0],
  },
  {
    Header: 'Transcript',
    accessor: 'transcript',
  },
  {
    Header: 'Effective Tax Rate',
    accessor: 'taxRate',
  },
  {
    Header: 'Net Capital Gain',
    accessor: 'capitalGain',
  },
];

const mapTranscriptTypeToAttachmentType = (type: 'TRT' | 'W&I' | 'ACCT'): 'RECORD_OF_ACCOUNT' | 'TRT' | 'WAGE_INCOME' => {
  switch (type) {
    case 'TRT':
      return 'TRT';
    case 'W&I':
      return 'WAGE_INCOME';
    case 'ACCT':
      return 'RECORD_OF_ACCOUNT';
    default:
      throw new Error(`Unknown transcript type: ${type}`);
  }
};


const SmartTaxModal: React.FC<SmartPortfolioProps> = ({
                                                        isOpen,
                                                        onClose,
                                                        orderId,
                                                      }) => {
  // Chakra Color Mode
  const textColor = useColorModeValue("navy.700", "white");

  const { selectedClient, user, } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [smarttaxData, setSmarttaxData] = useState<any>(undefined);
  const { isOpen: isTranscriptOpen, onOpen: onTranscriptOpen, onClose: onTranscriptClose } = useDisclosure();
  const { isOpen: isReportCardOpen, onOpen: onReportCardOpen, onClose: onReportCardClose } = useDisclosure();

  const [downloadingItems, setDownloadingItems] = useState<{ [key: string]: boolean }>({});

  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedType, setSelectedType] = useState<'TRT' | 'W&I' | 'ACCT'>('TRT');
  const [selectedTranscript, setSelectedTranscript] = useState(undefined); // Includes only TRT or W&I
  const [selectedTranscriptsForReport, setSelectedTranscriptsForReport] = useState(undefined); // This is for Report Card; Includes TRT and W&I

  useEffect(() => {
    if (selectedClient && orderId) {
      getSmartTaxData();
    }
  }, [selectedClient, orderId]);

  const getSmartTaxData = async () => {
    try {
      setIsLoading(true);
      if (orderId) {
        const { data } = await summaryReport(selectedClient.userId, orderId);
        setSmarttaxData(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleClose = () => {
    onClose();
  }

  const columns = useMemo(() => tableColumns, [tableColumns]);

  const trtData: any = useMemo(() => {
    if (!smarttaxData?.taxReturnTranscripts) return [];
    return smarttaxData.taxReturnTranscripts.sort((a: any, b: any) => b.period.localeCompare(a.period));
  }, [smarttaxData]);

  const wiData: any = useMemo(() => {
    if (!smarttaxData?.wageIncomeTranscripts) return [];
    return smarttaxData.wageIncomeTranscripts.sort((a: any, b: any) => b.period.localeCompare(a.period));
  }, [smarttaxData]);

  const acctData: any = useMemo(() => {
    if (!smarttaxData?.recordOfAccountTranscripts) return [];
    return smarttaxData.recordOfAccountTranscripts.sort((a: any, b: any) => b.period.localeCompare(a.period));
  }, [smarttaxData]);

  // Create table instances for each data set
  const trtTableInstance = useTable({ columns, data: trtData }, useGlobalFilter, useSortBy, usePagination);
  const wiTableInstance = useTable({ columns, data: wiData }, useGlobalFilter, useSortBy, usePagination);
  const acctTableInstance = useTable({ columns, data: acctData }, useGlobalFilter, useSortBy, usePagination);

  const handleViewTranscriptClick = (title: string, type: 'TRT' | 'W&I' | 'ACCT', transcript: any) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedTranscript(transcript);
    onTranscriptOpen(); // useDisclosure hook function to open the modal
  }

  // Function for opening the report card modal
  const handleViewReportCardClick = (title: string, type: 'TRT' | 'W&I' | 'ACCT', transcripts: any) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedTranscriptsForReport(transcripts);
    onReportCardOpen();
  }

  const handleDownloadClick = async (url: string, type: 'TRT' | 'W&I' | 'ACCT', transcript: any) => {
    const key = `${type}-${transcript.period}`;
    try {
      setDownloadingItems(prev => ({ ...prev, [key]: true }));
      const response = await downloadTranscript(selectedClient.userId, url)
      const binaryString = window.atob(response.data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const urlBlob = window.URL.createObjectURL(new Blob([bytes.buffer], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', `${type}-${transcript.period}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (err) {
      console.error('Error downloading file:', err);
    } finally {
      setDownloadingItems({});
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='full'>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader mt={6}>
          <HStack color={textColor} justify='space-between'>
            <Text fontSize='2xl'>{selectedClient?.firstName} {selectedClient?.lastName} smarttax™
            </Text>
            <Image cursor="pointer" src={user?.firm?.logoUrl} h='32px'
                   display={{ base: 'none', md: 'block' }}/>
          </HStack>
        </ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          {
            isLoading ? <Center h="100%" minH='400px' justifyContent="center" py="32px">
                <CirclesWithBar
                  height="120"
                  width="120"
                  color="#70ad45"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  outerCircleColor=""
                  innerCircleColor=""
                  barColor=""
                  ariaLabel="circles-with-bar-loading"
                />
              </Center> :
              <Box>
                <Text fontSize='lg' fontWeight='500'>REPORT
                  DATE: {smarttaxData?.date ? moment(smarttaxData.date).format('MMMM DD, YYYY') : 'N/A'}</Text>
                <Divider mb={1}/>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={1} mb={4}
                      alignItems='start'>
                  <HStack color={textColor}>
                    <Text fontSize='lg' fontWeight='600'>Total Liability:</Text>
                    <Text fontSize={{ base: 'xl', md: '2xl' }} color='red.500'
                          fontWeight='800'>${smarttaxData?.totalLiability ? currencyFormat(smarttaxData?.totalLiability) : 0}</Text>
                  </HStack>
                  <HStack color={textColor}>
                    <Text fontSize='lg' fontWeight='600'>Installment Agreement:</Text>
                    <Text fontSize={{ base: 'xl', md: '2xl' }} color='red.500'
                          fontWeight='800'>{smarttaxData?.installmentAgreementStatus}</Text>
                  </HStack>
                  <HStack color={textColor}>
                    <Text fontSize='lg' fontWeight='600'>Liability With Liens Field:</Text>
                    <Text fontSize={{ base: 'xl', md: '2xl' }} color='red.500'
                          fontWeight='800'>${smarttaxData?.totalLiabilityWithLiens ? currencyFormat(smarttaxData?.totalLiabilityWithLiens) : 0}</Text>
                  </HStack>
                  <HStack color={textColor}>
                    <Text fontSize='lg' fontWeight='600'>In Installment Agreement:</Text>
                    <Text fontSize={{ base: 'xl', md: '2xl' }} color='red.500'
                          fontWeight='800'>${smarttaxData?.totalInInstallmentAgreement ? currencyFormat(smarttaxData?.totalInInstallmentAgreement) : 0}</Text>
                  </HStack>
                  <HStack color={textColor}>
                    <Text fontSize='lg' fontWeight='600'>Liability At Risk For Levy:</Text>
                    <Text fontSize={{ base: 'xl', md: '2xl' }} color='red.500'
                          fontWeight='800'>${smarttaxData?.totalAtRiskForLevy ? currencyFormat(smarttaxData?.totalAtRiskForLevy) : 0}</Text>
                  </HStack>
                  <HStack color={textColor}>
                    <Text fontSize='lg' fontWeight='600'>Not In Installment Agreement:</Text>
                    <Text fontSize={{ base: 'xl', md: '2xl' }} color='red.500'
                          fontWeight='800'>${smarttaxData?.totalNotInInstallmentAgreement ? currencyFormat(smarttaxData?.totalNotInInstallmentAgreement) : 0}</Text>
                  </HStack>
                </Grid>

                {/* Tax Return Transcripts Table */}
                {smarttaxData?.taxReturnTranscripts?.length > 0 && (
                  <RenderTable data={smarttaxData} tableInstance={trtTableInstance} title="Tax Return Transcripts"
                               type='TRT' onClick={handleViewTranscriptClick} onDownloadClick={handleDownloadClick}
                               onViewReportCardClick={handleViewReportCardClick}
                               downloadingItems={downloadingItems}/>
                )}

                {/* Wage Income Transcripts Table */}
                {smarttaxData?.wageIncomeTranscripts?.length > 0 && (
                  <RenderTable data={smarttaxData} tableInstance={wiTableInstance} title="Wage Income Transcripts"
                               type='W&I' onClick={handleViewTranscriptClick} onDownloadClick={handleDownloadClick}
                               onViewReportCardClick={handleViewReportCardClick}
                               downloadingItems={downloadingItems}/>
                )}

                {/* Record of Account Transcripts Table */}
                {smarttaxData?.recordOfAccountTranscripts?.length > 0 && (
                  <RenderTable data={smarttaxData} tableInstance={acctTableInstance}
                               title="Record of Account Transcripts" type='ACCT' onClick={handleViewTranscriptClick}
                               onViewReportCardClick={handleViewReportCardClick}
                               onDownloadClick={handleDownloadClick} downloadingItems={downloadingItems}/>
                )}
              </Box>
          }
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="brandScheme" variant="ghost" onClick={() => handleClose()}>Close</Button>
        </ModalFooter>
      </ModalContent>
      {
        selectedTranscript &&
        <TranscriptModal isOpen={isTranscriptOpen} onClose={onTranscriptClose} title={selectedTitle} type={selectedType}
                         transcript={selectedTranscript}/>
      }
      {selectedTranscriptsForReport &&
        <SmarttaxReportCardModal isOpen={isReportCardOpen} onClose={onReportCardClose} title={selectedTitle}
                                 type={selectedType}
                                 transcripts={selectedTranscriptsForReport}/>}
    </Modal>
  );
}

// RenderTable component to refactor table rendering
const RenderTable = ({
                       data,
                       tableInstance,
                       title,
                       type,
                       onClick,
                       onDownloadClick,
                       onViewReportCardClick,
                       downloadingItems
                     }: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance;

  const getDownloadLink = (transcript: any, type: 'TRT' | 'W&I' | 'ACCT') => {
    const attachmentType = mapTranscriptTypeToAttachmentType(type);
    const year = transcript.period.split('-')[0];
    const entry = data.attachments.find((att: any) => att.type === attachmentType && att.period === year);
    return entry ? entry.links.find((link: any) => link.rel === 'self').href : '';
  };

  const getTranscriptWageScript = (year: number) => {
    const taxReturnTranscript = data.taxReturnTranscripts.find((att: any) => att.period === year);
    const wageIncomeTranscript = data.wageIncomeTranscripts.find((att: any) => att.period === year);
    return { 'TRT': taxReturnTranscript, 'W&I': wageIncomeTranscript }
  };

  return (
    <Box overflowX="auto">
      <Divider mt={4}/>
      <Text color='brand.500' fontSize='xl' fontWeight='700' mt={2}>{title}</Text>
      <Table {...getTableProps()} variant="striped" color="gray.500" mt={2}>
        <Thead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any, index: number) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor="transparent">
                  <Flex
                    justify="space-between"
                    align="center"
                    color="gray.400">
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {
            rows.map((row: any, index: number) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell: any, index: number) => {
                    if (cell.column.Header === 'Type') {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize='md'
                          py="8px"
                          borderColor="transparent">
                          <Badge colorScheme='green'>{type}</Badge>
                        </Td>
                      )
                    }
                    if (cell.column.Header === 'Transcript') {
                      const transcriptKey = `${type}-${cell.row.original.period}`;
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize='md'
                          py="8px"
                          borderColor="transparent"
                        >
                          <Flex gap={2}>
                            <Button size='sm' colorScheme='brand' leftIcon={<IoMdEye/>}
                                    onClick={() => onClick(title, type, cell.row.original)}>
                              View Transcript
                            </Button>
                            <Button size='sm' colorScheme='teal' leftIcon={<IoIosBook/>}
                                    onClick={() => onViewReportCardClick(title, type, getTranscriptWageScript(cell.row.original.period))}>
                              Report Card
                            </Button>
                            <Button
                              size='sm' colorScheme='blue'
                              leftIcon={<IoMdDownload/>}
                              isLoading={!!downloadingItems[transcriptKey]}
                              onClick={() => onDownloadClick(getDownloadLink(cell.row.original, type), type, cell.row.original)}
                            >
                              Download
                            </Button>
                          </Flex>
                        </Td>
                      )
                    }
                    if (cell.column.Header === 'Effective Tax Rate' && type === 'TRT') {
                      // calculate tax rate
                      let totalTaxLiabilityTPFiguresSum = 0;
                      let adjustedGrossIncomePerComputerSum = 0;

                      row.original.sections.forEach((section: any) => {
                        // Check if the section has the items array
                        if (section.items) {
                          // Iterate through the items array
                          section.items.forEach((item: any) => {
                            // Check for "TOTAL TAX LIABILITY TP FIGURES" and add it to the sum
                            if (item.description === "TOTAL TAX LIABILITY TP FIGURES PER COMPUTER") {
                              totalTaxLiabilityTPFiguresSum += parseFloat(item.value.replace('$', '').replace(',', ''));
                            }
                            // Check for "ADJUSTED GROSS INCOME PER COMPUTER" and add it to the sum
                            if (item.description === "ADJUSTED GROSS INCOME PER COMPUTER") { // TOTAL INCOME
                              adjustedGrossIncomePerComputerSum += parseFloat(item.value.replace('$', '').replace(',', ''));
                            }
                          });
                        }
                      });

                      // Calculate effective tax rate if AGI is not zero
                      const effectiveTaxRate = adjustedGrossIncomePerComputerSum !== 0
                        ? (totalTaxLiabilityTPFiguresSum / adjustedGrossIncomePerComputerSum) * 100
                        : 0;

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize='md'
                          py="8px"
                          borderColor="transparent">
                          {effectiveTaxRate.toFixed(2)}%
                        </Td>
                      )
                    }

                    if (cell.column.Header === 'Net Capital Gain' && type === 'TRT') {
                      // calculate tax rate
                      let netCapitalGain = 'N/A';

                      row.original.sections.forEach((section: any) => {
                        // Check if the section has the items array
                        if (section.items) {
                          // Iterate through the items array
                          section.items.forEach((item: any) => {
                            // Check for "NET CAPITAL GAINS" and add it to the sum
                            if (item.description === "F8995 NET CAPITAL GAINS") {
                              netCapitalGain = item.value;
                            }
                          });
                        }
                      });

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize='md'
                          py="8px"
                          borderColor="transparent">
                          {netCapitalGain}
                        </Td>
                      )
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize='md'
                        py="8px"
                        borderColor="transparent">
                        {cell.value}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          }
        </Tbody>
      </Table>
    </Box>
  );
}

export default SmartTaxModal;
