/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../auth-context/auth.context';
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Radio,
  RadioGroup,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Column, useGlobalFilter, usePagination, useSortBy, useTable, } from 'react-table';
import Card from '../../../components/card/Card';
import TableSpinner from '../../../components/spinner/TableSpinner';
import { AddIcon, SearchIcon, WarningIcon } from '@chakra-ui/icons';
import { MdAttachMoney, MdLoop, MdMenuBook, MdPersonRemove, MdRemoveRedEye, MdSend } from 'react-icons/md';
import AddClientModal from '../../admin/modals/components/AddClientModal';
import {
  addClient,
  deleteUserAsAdvisor,
  getAllClients,
  remindInvitation,
  resetPasswordRequest
} from '../../../api/user-service';
import { PopoverTrigger } from '../../admin/dataTables/components/ClientTable';
import {
  getAllLinkedAccountsAsAdvisor,
  getAllLinkedInsuranceAccountsAsAdvisor
} from '../../../api/institution-service';
import { formatInsuranceProviderName, getBadgeColor, getColorByFirstChar } from "../../../libraries/utils";
import { ClientType } from "../../../types/user";
import ResetPasswordModal from "../../admin/modals/components/ResetPasswordModal";
import { COUNT_PER_PAGE } from "../../../config/constant";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination as useAjnaPagination
} from "@ajna/pagination";
import { mode } from "@chakra-ui/theme-tools";
import { useDebounce } from "../../../libraries/useDebounce";
import moment from "moment/moment";
import OfficeInviteModal from "../../admin/modals/components/OfficeInviteModal";

const { REACT_APP_SMARTLEAD_BASE_URL = '' } = process.env;

const CLIENT_COLUMNS: Column<ClientType>[] | any = [
  {
    Header: 'NAME',
    accessor: 'fullName',
  },
  // {
  //   Header: 'MANAGEMENT',
  //   accessor: 'management',
  // },
  {
    Header: 'SOURCE',
    accessor: 'source',
  },
  {
    Header: 'PORTFOLIO STATUS',
    accessor: 'accepted',
  },
  {
    Header: 'TAX STATUS',
    accessor: 'taxStatus',
  },
  {
    Header: 'VIEW DATA',
    accessor: 'options',
  },
  {
    Header: 'OTHER',
    accessor: 'other',
  },
];

const useQuery = () => {
  return new URLSearchParams(window.location.search);
}

const ClientList: React.FC = () => {
  const { user, selectedClient, setSelectedClient } = useAuth();
  const history = useHistory();
  const toast = useToast();

  const bgPagination = useColorModeValue('brand.500', 'brand.400');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [clients, setClients] = useState<ClientType[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedClientData, setSelectedClientData] = useState<any>(null);
  const [isInstitutionLoading, setIsInstitutionLoading] = useState<boolean>(false);
  const [isInsuranceInstitutionLoading, setIsInsuranceInstitutionLoading] = useState<boolean>(false);
  const [linkedInstitutions, setLinkedInstitutions] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useState(false);
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetClose } = useDisclosure();
  const [reportType, setReportType] = useState<'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY'>('TEN_DAY');
  const [inviteType, setInviteType] = useState<'CONNECT' | 'OFFICE'>('CONNECT');
  const [openedPopoverId, setOpenedPopoverId] = useState<any>(null);
  const [openedCategory, setOpenedCategory] = useState<'Finance' | 'Tax' | undefined>(undefined);
  const [connectType, setConnectType] = useState<'CONNECT' | 'UPLOAD' | 'OFFICE'>('CONNECT');
  const { isOpen: isOfficeOpen, onOpen: onOfficeOpen, onClose: onOfficeClose } = useDisclosure();
  const debouncedSearch = useDebounce(search, 1000);
  const [officeInviteURL, setOfficeInviteURL] = useState('');

  const query = useQuery();
  const reason = query.get("reason");
  const code = query.get("code");

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    offset,
    pageSize,
  } = useAjnaPagination({
    pagesCount: Math.ceil(total / COUNT_PER_PAGE),
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: 1,
      pageSize: COUNT_PER_PAGE,
    },
  });

  useEffect(() => {
    if (reason === 'wealthbox_return' && code === '200') {
      toast({
        title: 'You have been successfully connected to Wealthbox.',
        description: `We are currently retrieving your prospects and initiating the invitation process.`,
        status: 'success',
        duration: 10000,
        isClosable: false,
      });
      setTimeout(() => {
        fetchClients();
        history.push(window.location.pathname);
      }, 10000);
    } else if (reason === 'redtail_return' && code === '200') {
      toast({
        title: 'You have been successfully connected to REDTAIL.',
        description: `We are currently retrieving your prospects and initiating the invitation process.`,
        status: 'success',
        duration: 10000,
        isClosable: false,
      });
      setTimeout(() => {
        fetchClients();
        history.push(window.location.pathname);
      }, 10000);
    }
  }, [reason]);

  const tableInstance = useTable(
    {
      columns: CLIENT_COLUMNS,
      data: clients,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const nameColor = useColorModeValue('blue.600', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const textColorSecondary = 'secondaryGray.600';

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const { clients, count } = await getAllClients(pageSize, offset, search);
      setClients(clients);
      setTotal(count)
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewData = (data: any, type: 'Finance' | 'Tax' = 'Finance') => {
    const clientData: ClientType = { ...data };
    setSelectedClient(clientData);
    if (clientData.management === 'insurance') {
      history.push('/admin/insurance');
    } else if (type === 'Finance') {
      history.push('/admin/wealth');
    } else if (type === 'Tax') {
      history.push('/admin/smarttax');
    }
  };

  const fetchLinkedAccounts = (client: any) => {
    setIsInstitutionLoading(true);
    getAllLinkedAccountsAsAdvisor(client.userId)
      .then((result) => {
        setLinkedInstitutions(result);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsInstitutionLoading(false))
  }

  const fetchLinkedInsuranceAccounts = (client: any) => {
    setIsInsuranceInstitutionLoading(true);
    getAllLinkedInsuranceAccountsAsAdvisor(client.userId)
      .then((result) => {
        setLinkedInstitutions(result);
      })
      .catch(err => console.error(err.message))
      .finally(() => setIsInsuranceInstitutionLoading(false))
  }

  useEffect(() => {
    setSelectedClient(null);
    if (user) {
      fetchClients();
    }
  }, [user, currentPage, offset, debouncedSearch]);

  useEffect(() => {
    if (selectedClientData) {
      if (selectedClientData?.management === 'insurance') {
        fetchLinkedInsuranceAccounts(selectedClientData);
      } else {
        fetchLinkedAccounts(selectedClientData);
      }
    }
  }, [selectedClientData])

  const addNewClient = async ({
                                addressLine1,
                                birthday,
                                city,
                                companyName,
                                email,
                                firstName,
                                lastName,
                                management,
                                middleName,
                                nickName,
                                phoneNumber,
                                postalCode,
                                state,
                                userRole,
                                note,
                                category,
                                isQuickAdd,
                                connectType,
                                reportType,
                              }: {
    firstName: string,
    middleName: string,
    lastName: string,
    nickName: string,
    birthday: string,
    email: string,
    phoneNumber: string,
    userRole: string,
    management: string,
    addressLine1: string,
    city: string,
    state: string,
    postalCode: string,
    companyName: string,
    note: string,
    category: 'Finance' | 'Tax',
    isQuickAdd: boolean,
    connectType?: 'CONNECT' | 'UPLOAD' | 'OFFICE',
    reportType?: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY'
  }) => {
    try {
      await addClient({
        addressLine1,
        birthday,
        city,
        companyName,
        receiverEmail: email,
        firstName,
        lastName,
        management,
        middleName,
        nickName,
        phoneNumber,
        postalCode,
        state,
        userRole,
        note,
        category,
        isQuickAdd,
        connectType,
        reportType,
      })
      toast({
        title: 'Add Prospect',
        description: `${firstName} has been successfully added as a prospect.`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      fetchClients();
    } catch (error: any) {
      toast({
        title: 'Add Prospect',
        description: error.response?.data.message ?? error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  };

  const removeClient = (clientId: any) => {
    setIsDeleting(true);
    deleteUserAsAdvisor(clientId)
      .then(() => {
        toast({
          title: 'Remove Client',
          description: `Client has been removed.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchClients();
      })
      .catch((err) => {
        toast({
          title: 'Remove Client',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsDeleting(false);
      })
  }

  const resetPassword = async ({ isAutoGenerated, password, askToChange }: {
    isAutoGenerated: boolean,
    password?: string,
    askToChange?: boolean,
  }) => {
    await resetPasswordRequest(selectedClientData.userId, isAutoGenerated, password, askToChange)
      .then(() => {
        toast({
          title: 'Reset Password',
          description: `The password of ${selectedClientData.firstName} ${selectedClientData.lastName} has been reset.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: 'Reset Password',
          description: err?.response?.data?.message || err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  const resendInvitationEmail = (userId: number, category: 'Finance' | 'Tax', connectType?: 'CONNECT' | 'UPLOAD' | 'OFFICE', reportType?: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY') => {
    remindInvitation(userId, category, connectType, reportType)
      .then(() => {
        toast({
          title: 'Email reminder has been sent.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Updates the clients state
        setClients(prevClients =>
          prevClients.map(client =>
            client.userId === userId
              ? {
                ...client,
                resentInvitation: true,
                ...(category === 'Tax' ? { isTax: true } : {})
              }
              : client
          )
        );
      })
      .catch((err) => {
        toast({
          title: 'Remind Invitation',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      })
  }

  const getTaxStatus = (value?: 'ACTIVE' | 'INACTIVE' | 'REJECTED' | 'CLOSED' | 'INVITE SENT') => {
    if (value === 'ACTIVE') {
      return 'ACTIVE';
    } else if (value === 'INACTIVE') {
      return 'SUBMITTED';
    } else if (value === 'REJECTED') {
      return 'REJECTED';
    } else if (value === 'CLOSED') {
      return 'CLOSED';
    } else if (value === 'INVITE SENT') {
      return 'INVITE SENT';
    } else {
      return 'NOT INVITED';
    }
  }

  const getDeliverDate = (reportType: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY', date: Date) => {
    switch (reportType) {
      case 'SAME_DAY':
        return moment(date).format('MMMM DD, YYYY');
      case 'NEXT_DAY':
        // Add one day to the given date
        return moment(date).add(1, 'days').format('MMMM DD, YYYY');
      case 'TEN_DAY':
        // Add 10 days to the given date
        return moment(date).add(10, 'days').format('MMMM DD, YYYY');
      default:
        return '';
    }
  };

  const getInviteSentDate = (date?: Date) => {
    if (date) {
      return moment(date).format('MMMM DD, YYYY');
    } else {
      return '';
    }
  };

  const getSourceColor = (source?: 'internal' | 'smartleads' | 'Wealthbox' | 'Redtail' | 'API'): string => {
    switch (source) {
      case 'internal':
        return 'green';
      case 'smartleads':
        return 'orange';
      case 'Wealthbox':
        return 'navy';
      case 'Redtail':
        return 'red';
      case 'API':
        return 'blue';
      default:
        return 'red';
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <VStack>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX='auto'
          opacity={isLoading ? 0.7 : 1}
        >
          <TableSpinner loading={isLoading}/>
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <HStack>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  zIndex={0}
                  children={<SearchIcon color="gray.300"/>}
                />
                <Input
                  type="input"
                  placeholder="Search Email or Name..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </HStack>
            <Button
              leftIcon={<AddIcon/>}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              minW="110px"
              ml="8px"
              onClick={onOpen}
            >
              Add Prospect
            </Button>
          </Flex>
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            size="sm"
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    bgColor={row.original.userId === selectedClient?.userId ? '#dddddd' : 'transparent'}
                    _hover={{ background: '#dddddd55' }}
                  >
                    {row.cells.map((cell, index) => {
                      let data: ReactNode;
                      if (cell.column.Header === 'NAME') {
                        data = (
                          <HStack key={index}>
                            <Avatar
                              src={cell.row.original?.avatarUrl}
                              name={`${cell.row.original.firstName} ${cell.row.original.lastName}`}
                              w="32px"
                              h="32px"
                              size='12px'
                            />
                            <VStack align='start' spacing={1}>
                              <Tooltip
                                label={`View details of ${cell.row.original.firstName} ${cell.row.original.lastName}`}
                                hasArrow placement="bottom">
                                <Link href={`/admin/clients/${cell.row.original.userId}`}>
                                  <Text
                                    color={nameColor}
                                    textDecoration="underline"
                                    textUnderlineOffset={2}
                                    fontSize="sm"
                                    fontWeight="700"
                                  >
                                    {cell.row.original.firstName} {cell.row.original.lastName}
                                  </Text>
                                </Link>
                              </Tooltip>
                              <Text>{cell.row.original.email}</Text>
                            </VStack>
                          </HStack>
                        );
                      } else if (cell.column.Header === 'SOURCE') {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="600"
                          >
                            Created by <Badge ml='2' colorScheme={getSourceColor(cell.row.original?.source)}
                                              variant='outline'>
                            {cell.row.original?.source}
                          </Badge>
                          </Text>
                        );
                      } else if (cell.column.Header === 'PORTFOLIO STATUS' || cell.column.Header === 'MANAGEMENT') {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {
                              cell.column.Header === 'MANAGEMENT' ?
                                <Badge
                                  colorScheme={cell.value === 'insurance' ? 'purple' : 'pink'}>{cell.value}
                                </Badge> :
                                <HStack>
                                  <Tooltip placement='top'
                                           label={!cell.value ? `Invite sent on ${getInviteSentDate(cell.row.original?.isFinanceAt || cell.row.original.createdAt)}` : ''}
                                  >
                                    <Badge
                                      colorScheme={cell.value ? 'green' : cell.row.original?.resentInvitation ? 'orange' : 'red'}>{cell.value ? 'ACCEPTED' : cell.row.original?.resentInvitation ? 'NOT ACCEPTED' : 'NOT INVITED'}
                                    </Badge>
                                  </Tooltip>
                                  {!cell.value && (
                                    <Tooltip hasArrow placement="top"
                                             label={cell.row.original?.linkOpened ? 'Invite has been opened' : 'Invite has not been opened'}>
                                      <Box w='12px' h='12px' borderRadius='full'
                                           bg={cell.row.original?.linkOpened ? 'green.500' : 'red.500'}/>
                                    </Tooltip>
                                  )}
                                </HStack>
                            }
                          </Text>
                        );
                      } else if (cell.column.Header === 'TAX STATUS') {
                        const taxStatus = (!cell.row.original.smarttaxClients.status && cell.row.original.isTax) ? 'INVITE SENT' : cell.row.original.smarttaxClients.status;

                        data = (
                          <HStack>
                            <Tooltip
                              label={
                                getTaxStatus(taxStatus) === 'SUBMITTED' ? `Expected by ${getDeliverDate(cell.row.original.smarttaxClients.reportType, cell.row.original.smarttaxClients.createdAt)}` :
                                  getTaxStatus(taxStatus) === 'INVITE SENT' ? `Invite sent on ${getInviteSentDate(cell.row.original?.isTaxAt || cell.row.original.createdAt)}` : ''}
                              placement='top'>
                              <Text
                                color={textColor}
                                fontSize="sm"
                                fontWeight="700"
                              >
                                <Badge
                                  colorScheme={taxStatus ? getBadgeColor(taxStatus) : 'red'}>
                                  {getTaxStatus(taxStatus)}
                                </Badge>
                              </Text>
                            </Tooltip>
                          </HStack>
                        );
                      } else if (cell.column.Header === 'VIEW DATA') {
                        const disabled: boolean = !row.cells.filter(
                          (item) => item.column.Header === 'PORTFOLIO STATUS'
                        )[0].value;

                        const taxEnabled: boolean = cell.row.original.smarttaxClients.status === 'ACTIVE';

                        data = (
                          <HStack>
                            {
                              cell.row.original.accepted ?
                                <Button
                                  rightIcon={<MdMenuBook/>}
                                  size="sm"
                                  fontSize="sm"
                                  variant="brand"
                                  fontWeight="500"
                                  minW='162px'
                                  disabled={disabled}
                                  onClick={() => viewData(row.original)}
                                >
                                  {cell.row.original.management === 'insurance' ? 'View Insurance Data' : 'View Portfolio'}
                                </Button> :
                                <Popover placement="bottom"
                                         isOpen={openedPopoverId === row.original.userId && openedCategory === 'Finance'}>
                                  <PopoverTrigger>
                                    <Button
                                      rightIcon={<MdSend/>}
                                      size="sm"
                                      fontSize="sm"
                                      colorScheme="blue"
                                      fontWeight="500"
                                      minW='162px'
                                      onClick={() => {
                                        setSelectedClientData(row.original);
                                        setOpenedPopoverId(row.original.userId);
                                        setOpenedCategory('Finance');
                                      }}
                                    >
                                      Invite Finance
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent w="auto">
                                    <PopoverHeader fontWeight="semibold" pr={8} maxW='240px'>
                                      Are you sure you want to
                                      invite {cell.row.original.firstName} {cell.row.original.lastName} to
                                      smartportfolio™?
                                    </PopoverHeader>
                                    <PopoverArrow/>
                                    <PopoverCloseButton onClick={() => {
                                      setOpenedPopoverId(null);
                                      setOpenedCategory(undefined);
                                    }}/>
                                    <PopoverBody maxW='240px'>
                                      <FormLabel>Invite Type</FormLabel>
                                      <RadioGroup value={connectType}
                                                  onChange={(e: 'CONNECT' | 'UPLOAD' | 'OFFICE') => setConnectType(e)}>
                                        <HStack>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='CONNECT'>CONNECT</Radio>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='UPLOAD'>UPLOAD</Radio>
                                        </HStack>
                                        <Radio mt={2} colorScheme="brandScheme" fontSize='sm'
                                               value='OFFICE'>OFFICE UPLOAD</Radio>
                                        <HStack w='full' justify='end'>
                                          <Button
                                            mt={2}
                                            size='sm'
                                            colorScheme='brand'
                                            onClick={() => {
                                              setOpenedPopoverId(null);
                                              if (connectType === 'OFFICE') {
                                                setOfficeInviteURL(`${REACT_APP_SMARTLEAD_BASE_URL}/s/${user?.linkSlug}?email=${encodeURIComponent(selectedClientData?.email)}&type=OFFICE`);
                                                onOfficeOpen();
                                              } else {
                                                resendInvitationEmail(cell.row.original.userId, 'Finance', connectType);
                                              }
                                            }}
                                          >{connectType === 'OFFICE' ? 'Open' : 'Send'}</Button>
                                        </HStack>
                                      </RadioGroup>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                            }
                            {
                              cell.row.original?.smarttaxClients?.status ?
                                <Button
                                  rightIcon={<MdAttachMoney/>}
                                  size="sm"
                                  minW='162px'
                                  fontSize="sm"
                                  variant="brand"
                                  fontWeight="500"
                                  disabled={!taxEnabled}
                                  onClick={() => viewData(row.original, 'Tax')}
                                >
                                  View Tax
                                </Button> :
                                <Popover placement="bottom"
                                         isOpen={openedPopoverId === row.original.userId && openedCategory === 'Tax'}>
                                  <PopoverTrigger>
                                    <Button
                                      rightIcon={<MdSend/>}
                                      size="sm"
                                      fontSize="sm"
                                      minW='162px'
                                      colorScheme="blue"
                                      fontWeight="500"
                                      onClick={() => {
                                        setSelectedClientData(row.original);
                                        setOpenedPopoverId(row.original.userId);
                                        setOpenedCategory('Tax');
                                      }}
                                    >
                                      Invite Tax
                                    </Button>
                                  </PopoverTrigger>
                                  <PopoverContent w="auto">
                                    <PopoverHeader fontWeight="semibold" pr={8} maxW='240px'>
                                      Are you sure you want to
                                      invite {cell.row.original.firstName} {cell.row.original.lastName} to smarttax™?
                                    </PopoverHeader>
                                    <PopoverArrow/>
                                    <PopoverCloseButton onClick={() => {
                                      setOpenedPopoverId(null);
                                      setOpenedCategory(undefined);
                                    }}/>
                                    <PopoverBody>
                                      <FormLabel>Report Type</FormLabel>
                                      <RadioGroup value={reportType}
                                                  onChange={(e: 'SAME_DAY' | 'NEXT_DAY' | 'TEN_DAY') => setReportType(e)}>
                                        <HStack>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='SAME_DAY'>SAME DAY</Radio>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='NEXT_DAY'>NEXT DAY</Radio>
                                        </HStack>
                                        <Radio mt={2} colorScheme="brandScheme" fontSize='sm'
                                               value='TEN_DAY'>WITHIN 10 DAYS</Radio>
                                      </RadioGroup>
                                      <Divider mt={2}/>
                                      <FormLabel mt={2}>Invite Type</FormLabel>
                                      <RadioGroup value={inviteType}
                                                  onChange={(e: 'CONNECT' | 'OFFICE') => setInviteType(e)}>
                                        <HStack>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='CONNECT'>CONNECT</Radio>
                                          <Radio colorScheme="brandScheme" fontSize='sm'
                                                 value='OFFICE'>OFFICE</Radio>
                                        </HStack>
                                      </RadioGroup>
                                      <HStack w='full' justify='end'>
                                        <Button
                                          mt={2}
                                          size='sm'
                                          colorScheme='brand'
                                          onClick={() => {
                                            setOpenedPopoverId(null);
                                            if (inviteType === 'OFFICE') {
                                              setOfficeInviteURL(`${REACT_APP_SMARTLEAD_BASE_URL}/t/${user?.linkSlug}/generate?email=${encodeURIComponent(selectedClientData?.email)}&reportType=${reportType}&type=${inviteType}`);
                                              onOfficeOpen();
                                            } else {
                                              resendInvitationEmail(cell.row.original.userId, 'Tax', undefined, reportType);
                                            }
                                          }}
                                        >{inviteType === 'OFFICE' ? 'Open' : 'Send'}</Button>
                                      </HStack>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Popover>
                            }
                          </HStack>
                        );
                      } else {
                        const disabled: boolean = !row.cells.filter(
                          (item) => item.column.Header === 'PORTFOLIO STATUS'
                        )[0].value;

                        data = (
                          <HStack>
                            <Popover placement="auto-start">
                              <PopoverTrigger>
                                <Button
                                  rightIcon={<MdRemoveRedEye/>}
                                  size="sm"
                                  fontSize="sm"
                                  colorScheme="green"
                                  fontWeight="500"
                                  disabled={disabled}
                                  onClick={() => {
                                    setLinkedInstitutions([]);
                                    setSelectedClientData(row.original);
                                  }}
                                >
                                  Institutions
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent w="auto" minW="280px">
                                <PopoverHeader fontWeight="semibold">Connected
                                  Institutions</PopoverHeader>
                                <PopoverArrow/>
                                <PopoverCloseButton/>
                                <PopoverBody>
                                  {
                                    (isInstitutionLoading || isInsuranceInstitutionLoading) ?
                                      <CircularProgress size={8} isIndeterminate
                                                        color="green.300"/> :
                                      (linkedInstitutions.length > 0 ? linkedInstitutions.map((ins: any, lIndex: number) =>
                                          selectedClientData?.management === 'insurance' ?
                                            <Flex align="center" mb="4px" key={lIndex}>
                                              <HStack>
                                                <Box
                                                  bg={getColorByFirstChar(ins.insuranceProviderName)}
                                                  borderRadius="50%"
                                                  minW="20px"
                                                  width="20px"
                                                  height="20px"
                                                  display="flex"
                                                  alignItems="center"
                                                  justifyContent="center"
                                                />
                                                <Text
                                                  color={textColor}
                                                  fontSize="sm"
                                                  fontWeight="600">
                                                  {formatInsuranceProviderName(ins?.insuranceProviderName)}
                                                </Text>
                                              </HStack>
                                            </Flex> :
                                            <Flex align="center" mb="4px">
                                              <Avatar
                                                src={ins?.branding?.icon || ins?.branding?.logo}
                                                w="24px"
                                                h="24px"
                                                me="8px"
                                              />
                                              <HStack>
                                                <Text
                                                  color={textColor}
                                                  fontSize="sm"
                                                  fontWeight="600">
                                                  {ins?.institutionName}
                                                </Text>
                                                <Text
                                                  fontWeight="500"> ({ins?.items[0]?.accounts.length} {ins?.items[0]?.accounts.length > 1 ? 'accounts' : 'account'} linked)
                                                </Text>
                                              </HStack>
                                            </Flex>) :
                                        <Text
                                          color={textColorSecondary}
                                          fontSize="sm"
                                          fontWeight="500">
                                          No institutions linked yet. 😔
                                        </Text>)

                                  }
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                            <Tooltip hasArrow label='Reset password'>
                              <IconButton
                                aria-label="Reset password"
                                bg="inherit"
                                ms="auto"
                                borderRadius="inherit"
                                onClick={() => {
                                  onResetOpen();
                                  setSelectedClientData(row.original);
                                }}
                                _active={{
                                  bg: 'inherit',
                                  transform: 'none',
                                  borderColor: 'transparent',
                                }}
                                _focus={{
                                  boxShadow: 'none',
                                }}
                                icon={
                                  <MdLoop/>
                                }/>
                            </Tooltip>
                            <Popover placement="auto-start">
                              {({ onClose }) => (
                                <>
                                  <PopoverTrigger>
                                    <IconButton
                                      aria-label="Remove client"
                                      bg="inherit"
                                      ms="auto"
                                      borderRadius="inherit"
                                      _active={{
                                        bg: 'inherit',
                                        transform: 'none',
                                        borderColor: 'transparent',
                                      }}
                                      _focus={{
                                        boxShadow: 'none',
                                      }}
                                      icon={
                                        <MdPersonRemove
                                          color="secondaryGray.500"/>
                                      }></IconButton>
                                  </PopoverTrigger>
                                  <PopoverContent w="auto" minW="280px">
                                    <PopoverHeader fontWeight="semibold">Remove
                                      Client</PopoverHeader>
                                    <PopoverArrow/>
                                    <PopoverCloseButton/>
                                    <PopoverBody>
                                      <Box display="flex" marginBottom="8px">
                                        <WarningIcon color="orange"
                                                     marginRight="4px"
                                                     marginTop="4px"/>
                                        <Box>Are you sure to
                                          remove <b>{cell.row.original.firstName} {cell.row.original.lastName}</b>?</Box>
                                      </Box>
                                      <Box display="flex"
                                           justifyContent="flex-end">
                                        <Button size="xs" margin="4px"
                                                width="50px"
                                                onClick={onClose}>No</Button>
                                        <Button isLoading={isDeleting}
                                                isDisabled={isDeleting}
                                                size="xs" margin="4px"
                                                width="50px" colorScheme="red"
                                                onClick={() => {
                                                  removeClient(row.original.userId);
                                                  onClose();
                                                }}>Yes</Button>
                                      </Box>
                                    </PopoverBody>
                                  </PopoverContent>
                                </>
                              )}
                            </Popover>
                          </HStack>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer
              align="center"
              justify="center"
              p={4}
              gap={2}
              w="full"
            >
              <PaginationPrevious
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              > Prev
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    w={10}
                    jumpSize={5}
                  />
                }
              >
                {pages.map((page) => (
                  <PaginationPage
                    key={`pagination_page_${page}`}
                    page={page}
                    w={10}
                    bg="transparent"
                    borderColor="brand.100"
                    borderWidth="1px"
                    fontSize="sm"
                    _hover={{
                      bg: 'brand.600'
                    }}
                    _current={{
                      bg: 'brand.600',
                      fontSize: 'sm',
                      w: 10
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                _hover={{
                  bg: mode('brand.600', 'brand.400'),
                }}
                bg={bgPagination}
              >Next</PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Card>
      </VStack>
      <AddClientModal isOpen={isOpen} onClose={onClose} onSave={addNewClient}/>
      <ResetPasswordModal isOpen={isResetOpen} onClose={onResetClose} user={selectedClientData}
                          onReset={resetPassword}/>
      <OfficeInviteModal isOpen={isOfficeOpen} onClose={onOfficeClose}
                         selectedUser={selectedClientData}
                         url={officeInviteURL}/>
    </Box>
  );
};

export default ClientList;
